import React from "react";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { Marker, InfoWindow } from "@react-google-maps/api";
import Loader from "../../../../../../Base/Loader";
import packageIcon from "../../../../../../../box.svg";
import OrderInfoCard from "./OrderInfoCard";
import { get } from "lodash";

// <img src="https://img.icons8.com/emoji/48/000000/package-.png"/>
function OrderMarkers(props) {
    const { panTo } = props;
    const [selected, setSelected] = React.useState(null);

    const unAssignedSelected = useSelector(
        (state) => state.map.unAssignedSelected
    );

    const showAll = useSelector((state) => state.map.showAll);
    const store_id = useSelector((state) => state.firebase.auth.uid);
    const branch_id = useSelector((state) => state.constant.constant.branch_id);

    const orders = useSelector((state) => {
        return get(
            state.firebase.ordered,
            `adminOrders.${store_id}.${branch_id}`
        );
    });

    // panTo clicked unAssigned order
    React.useEffect(() => {
        if (unAssignedSelected != null) {
            console.log("clicked");
            const { receiver_lat, receiver_long } = unAssignedSelected;
            panTo({
                lat: receiver_lat,
                lng: receiver_long,
            });
        } else {
            return "no order to panTo";
        }
    }, [unAssignedSelected, showAll]);

    if (!isLoaded(orders)) {
        return <Loader />;
    }

    if (isEmpty(orders)) {
        return null;
    }

    return (
        <div>
            {showAll && (
                <>
                    {orders.map((order) => {
                        return (
                            <Marker
                                key={order.key}
                                position={{
                                    lat: parseFloat(order.value.delivery_lat),
                                    lng: parseFloat(order.value.delivery_long),
                                }}
                                icon={{
                                    url: packageIcon,
                                    scaledSize: new window.google.maps.Size(
                                        30,
                                        30
                                    ),
                                    origin: new window.google.maps.Point(0, 0),
                                    anchor: new window.google.maps.Point(
                                        15,
                                        15
                                    ),
                                }}
                                onClick={() => {
                                    setSelected(order);
                                    console.log("clicked");
                                }}
                            />
                        );
                    })}

                    {/* render info */}
                    {selected ? (
                        <InfoWindow
                            position={{
                                lat: parseFloat(selected.value.delivery_lat),
                                lng: parseFloat(selected.value.delivery_long),
                            }}
                            onCloseClick={() => {
                                setSelected(null);
                            }}
                        >
                            <OrderInfoCard order={selected} />
                        </InfoWindow>
                    ) : null}
                </>
            )}
        </div>
    );
}

export default OrderMarkers;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import { isEmpty } from "react-redux-firebase";
import Chip from "@material-ui/core/Chip";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: "block",
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));

export default function OrderList(props) {
    const {
        orders,
        handlePanToOrder,
        handleRenderAll,
        handleToggleDetailsView,
        listType,
    } = props;

    const branch = useSelector((state) => state.constant.constant.branch);

    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    console.log(orders);
    // control rendering of all orders unassigned to map
    const [dense, setDense] = React.useState(false);

    // render all orders that are unassigned
    const renderAll = (event) => {
        setDense(event.target.checked);
        handleRenderAll(event.target.checked);
    };

    const handleListItemClick = (event, index, order) => {
        setSelectedIndex(index);

        // check type
        if (listType == "assigned") {
            handleToggleDetailsView({ order, toggle: true });
        } else {
            handlePanToOrder(order);
        }
    };

    // checking for null
    if (isEmpty(orders)) {
        return (
            <div className={classes.root}>
                <List component="nav" aria-label="secondary mailbox folder">
                    <ListItem
                        button
                        selected={selectedIndex === 2}
                        onClick={(event) => handleListItemClick(event, 2)}
                    >
                        <ListItemText primary="No orders found" />
                    </ListItem>
                </List>
            </div>
        );
    }

    return (
        <div className={classes.root}>
            {listType == "unassigned" && (
                <FormGroup row>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={dense}
                                onChange={(event) => renderAll(event)}
                            />
                        }
                        label="Render All Unassigned Orders"
                    />
                </FormGroup>
            )}
            <List component="nav" aria-label="orders">
                {orders.map(
                    (
                        {
                            key,
                            value: {
                                order_status = "unassigned",
                                receiver_address,

                                cartID,
                                time,
                                date,
                                client_name,
                                client_phone,
                                // sender_name,
                                // sender_phone,

                                clientID,
                                // sender_id,
                                // sender_lat,
                                // sender_long,
                                delivery_lat: receiver_lat,
                                delivery_long: receiver_long,
                                delivery_name,
                                delivery_type,
                                rider_id = undefined,
                                rider_lat = undefined,
                                rider_long = undefined,
                                rider_email = undefined,
                                rider_id_number = undefined,
                                rider_plate = undefined,
                                rider_image = undefined,
                                rider_name = undefined,
                                rider_phone = undefined,
                                ...rest
                            },
                        },
                        index
                    ) => (
                        <>
                            <ListItem
                                key={key}
                                button
                                selected={selectedIndex === index}
                                onClick={(event) => {
                                    const order = {
                                        order_status,
                                        receiver_address,
                                        receiver_lat,
                                        receiver_long,
                                        delivery_lat: receiver_lat,
                                        delivery_long: receiver_long,
                                        delivery_name,
                                        delivery_type,
                                        cartID,
                                        time,
                                        client_name,
                                        client_phone,
                                        ...branch,

                                        // sender_name,
                                        // sender_phone,
                                        date,
                                        clientID,

                                        // sender_id,
                                        // sender_lat,
                                        // sender_long,
                                        rider_id,
                                        rider_lat,
                                        rider_long,
                                        rider_email,
                                        rider_id_number,
                                        rider_plate,
                                        rider_image,
                                        rider_name,
                                        rider_phone,
                                        ...rest,
                                    };

                                    console.log(order, "==========");
                                    handleListItemClick(event, index, order);
                                }}
                            >
                                <ListItemText
                                    primary={client_name}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                className={classes.title}
                                                color="textSecondary"
                                                gutterBottom
                                            >
                                                {date} {time}
                                            </Typography>

                                            <Typography
                                                className={classes.title}
                                                color="textSecondary"
                                                gutterBottom
                                            >
                                                {client_phone}
                                            </Typography>
                                            <Chip
                                                size="small"
                                                label={`${
                                                    order_status ==
                                                        "assigned" ||
                                                    order_status ==
                                                        "assigned rider"
                                                        ? "package has been assigned"
                                                        : "pending"
                                                }`}
                                                clickable
                                                color={`${
                                                    order_status ==
                                                        "assigned" ||
                                                    order_status ==
                                                        "assigned rider"
                                                        ? "primary"
                                                        : "secondary"
                                                }`}
                                            />
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                            <Divider />
                        </>
                    )
                )}
            </List>
        </div>
    );
}

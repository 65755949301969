import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import Badge from "@material-ui/core/Badge";

import { useSelector } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import BasketDialog from "../../../../../../Base/People/PeopleDialog";
import BasketDetails from "./Basket/BasketDetails";

const useStyles = makeStyles((theme) => ({
    // locate: {
    //     position: "absolute",
    //     top: "2rem",
    //     right: "1rem",
    //     background: "none",
    //     border: "none",
    //     zIndex: "10",
    // },
    // shoppingBasket: {
    //     fontSize: "2rem",
    // },
}));

function Basket(props) {
    const classes = useStyles();
    const orders = useSelector((state) => state.basket.orders);

    return (
        <BasketDialog
            onSave={() => {}}
            title={`${orders.length} package${
                orders.length > 1 ? "s" : ""
            } in Basket`}
            render={(open) => (
                <div className={classes.locate}>
                    <Tooltip
                        title={"View all packages in basket"}
                        placement="bottom"
                    >
                        <Badge badgeContent={orders.length} color="secondary">
                            <Icon onClick={open}>
                                <ShoppingBasketIcon />
                            </Icon>
                        </Badge>
                    </Tooltip>
                </div>
            )}
        >
            <BasketDetails orders={orders} />
        </BasketDialog>
    );
}

export default Basket;

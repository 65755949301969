import React from "react";
import { useDispatch } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import { removeFromBasket } from "../../../../../../../../Redux/Actions/basketActions";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import AssignRiderModal from "../AssignRiderModal";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: "36ch",
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: "block",
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));

function BasketDetails({ orders, handleClose }) {
    const dispatch = useDispatch();
    const classes = useStyles();

    // const bull = <span className={classes.bullet}>•</span>;

    const handleRemoveFromBasket = (id) => {
        dispatch(removeFromBasket(id));
    };

    return (
        <List>
            {orders.length > 0 &&
                orders.map(
                    ({
                        order_status,
                        receiver_address,
                        receiver_lat,
                        receiver_long,
                        order_id,
                        order_time,
                        receiver_name,
                        receiver_phone,
                        sender_name,
                        sender_phone,
                        order_date,
                        receiver_id,
                        sender_id,
                        sender_lat,
                        sender_long,
                        rider_id = undefined,
                    }) => (
                        <>
                            <ListItem>
                                <ListItemText
                                    primary={receiver_name}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                className={classes.title}
                                                color="textSecondary"
                                                gutterBottom
                                            >
                                                {order_date} {order_time}
                                            </Typography>

                                            <Typography
                                                className={classes.title}
                                                color="textSecondary"
                                                gutterBottom
                                            >
                                                {receiver_phone}
                                            </Typography>
                                            <Typography
                                                className={classes.title}
                                                color="textPrimary"
                                                gutterBottom
                                            >
                                                {receiver_address}
                                            </Typography>
                                        </React.Fragment>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        color="secondary"
                                        onClick={() =>
                                            handleRemoveFromBasket(order_id)
                                        }
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider component="li" />
                        </>
                    )
                )}
            <div
                style={{
                    marginTop: "10px",
                }}
            ></div>
            <AssignRiderModal
                orders={orders}
                title="Assign the packages to a rider"
            />
        </List>
    );
}

export default BasketDetails;

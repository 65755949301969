import * as Yup from 'yup';

// form stuff
import { Field, Form, Formik } from 'formik';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ProgressBar from '../../../../Base/ProgressBarImages';
import React from 'react';
import { TextField } from 'formik-material-ui';
import UploadButton from '../../../../Base/UploadButton';
// redux
import { connect } from 'react-redux';
import { createNewProduct } from '../../../../../Redux/Actions/productActions';
import useImageHandler from '../../../../../Hooks/useImageHandler';

const validationSchema = Yup.object({
  product_name: Yup.string().required('product Name is required'),
  product_size: Yup.string().max(30, 'must not exceed 30 characters').required('product size eg 2kg is required'),
  product_price: Yup.number().required('product price is required'),
  refill_price: Yup.number().required('product refill price is required'),
  product_details: Yup.string().min(10).max(200).required('product details is required'),
  quantity: Yup.number().required('specify product quantity'),
  discount: Yup.number().required('specify product discount'),
});

function BranchProduct(props) {
  const { handleClose, createNewProduct, branch_id } = props;

  const { file, setFile, imageUrl, setImageUrl, imageChangeHandler } = useImageHandler();

  const createProduct = (values, action) => {
    const product = {
      ...values,
      branch_id,
      image_url:
        imageUrl == null ? 'https://i.pinimg.com/originals/c1/94/c7/c194c7141911512614e8418c8be92f33.jpg' : imageUrl,
    };
    createNewProduct({ product, closeModal: handleClose });
  };

  return (
    <Formik
      initialValues={{
        product_name: '',
        product_size: '',
        product_price: '',
        refill_price: '',
        product_details: '',
        quantity: '',
        discount: 0,
      }}
      validationSchema={validationSchema}
      onSubmit={createProduct}
    >
      <Form>
        <Grid container spacing={4}>
          <Grid item container xs={6}>
            <Grid item xs={12}>
              <Field name="product_name" type="text" label="Product Name" component={TextField} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Field name="product_size" type="text" label="Size" component={TextField} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Field name="product_price" type="number" label="Price" component={TextField} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Field name="refill_price" type="number" label="Refill Price" component={TextField} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Field name="product_details" type="text" label="Product Details" component={TextField} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Field name="quantity" type="number" label="Product Quantity" component={TextField} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Field name="discount" type="number" label="Product Discount" component={TextField} fullWidth />
            </Grid>
          </Grid>
          <Grid item container xs={6}>
            <Grid item xs={12}>
              {imageUrl ? (
                <img
                  style={{
                    width: '256px',
                    height: '256px',
                  }}
                  src={imageUrl}
                />
              ) : (
                <img
                  style={{
                    width: '256px',
                    height: '256px',
                  }}
                  src="https://www.ukje.shop/media/catalog/product/cache/aefcd4d8d5c59ba860378cf3cd2e94da/m/i/mintgroene-voetenzak-5-punts-gordel.jpg"
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {file == null && (
                <UploadButton
                  imageChangeHandler={imageChangeHandler}
                  title={file == null ? 'Upload Product Image' : 'Product Image Uploaded Successfully'}
                  fullWidth
                />
              )}

              {file && <ProgressBar file={file} setFile={setFile} setImageUrl={setImageUrl} />}
            </Grid>
          </Grid>

          <Grid item xs={8}>
            <Button variant="contained" color="primary" type="submit" size="medium">
              Ok
            </Button>

            <Button
              style={{
                marginLeft: '15px',
              }}
              variant="contained"
              color="secondary"
              onClick={handleClose}
              size="medium"
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
}

const mapActionsToProps = {
  createNewProduct,
};

export default connect(null, mapActionsToProps)(BranchProduct);

import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { format, parseISO } from 'date-fns';

import React from 'react';
import Title from '../Title';
import styled from 'styled-components';

export const CustomT = styled.div`
  .tooltip {
    border-radius: 0.25rem;
    background: #26313c;
    color: #fff;
    padding: 1rem;
    box-shadow: 15px 30px 40px 5px rgba(0, 0, 0, 0.5);
    text-align: center;
  }

  /* hiding first and last lines in cartesian grid */
  .recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child,
  .recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
    stroke-opacity: 0;
  }
`;

function ChartSummary({ data, value, date, label, currency = false, component, options, children, ...rest }) {
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Title>{label}</Title>
        {options && component}
      </div>
      <ResponsiveContainer
        width="100%"
        height={400}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <AreaChart data={data}>
          <defs>
            <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#2451b7" stopOpacity={0.4}></stop>
              <stop offset="75%" stopColor="#2451b7" stopOpacity={0.05}></stop>
            </linearGradient>
          </defs>
          <Area dataKey={value} stroke="#2451b7" fill="url(#color)" label={label} />
          {children}
          <XAxis
            dataKey={date}
            axisLine={false}
            tickLine={false}
            tickFormatter={(str) => {
              const date = parseISO(str.substr(0, 10));
              return format(date, 'MMM, d');
            }}
          />
          <YAxis
            dataKey={value}
            {...rest}
            // // domain={[0, data.length]}
            tickCount={8}
            tickFormatter={
              currency ? (number) => `${Number(number).toFixed(2)}` : (number) => `${Number(number).toFixed(0)}`
            }
          />
          <Tooltip content={<CustomTooltip currency={currency} />} />
          <CartesianGrid opacity={0.5} vertical={false} strokeDasharray="3 3" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

function CustomTooltip({ active, payload, label, currency }) {
  if (active) {
    return (
      <CustomT>
        <h4>{format(parseISO(label.substr(0, 10)), 'eee, d MMM, yyyy')}</h4>

        <p>
          {currency ? 'ksh' : null} {Number(payload[0].value).toFixed(1)} {currency ? null : 'orders'}{' '}
        </p>
      </CustomT>
    );
  }
  return null;
}

export default ChartSummary;

import OrderDetails from './orderDetails';
import OrderListTab from './tabsAndList';
import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

/*eslint-disable eqeqeq */
function OrderListContainerTab(props) {
  const toggleDetails = useSelector((state) => state.map.toggleDetails);
  return <> {toggleDetails == true ? <OrderDetails /> : <OrderListTab {...props} />}</>;
}

export default withRouter(OrderListContainerTab);

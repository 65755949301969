import { isLoaded, useFirebaseConnect, isEmpty } from 'react-redux-firebase';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Loader from '../../../../../../../Base/Loader';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { db } from '../../../../../../../../Utils/fb.config';
import { useEffect, useState } from 'react';
import { toArray } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'block',
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

function OrderCartDetails({ cartID, clientID }) {
  const classes = useStyles();
  const [items, setItems] = useState(null);

  useEffect(() => {
    const ref = db.ref(`shoppingCarts/${clientID}/${cartID}`);
    const arr = [];

    ref.on('value', (snap) => {
      // check if we have childeren to loop over

      if (snap.exists()) {
        snap.forEach((childSnap) => {
          const item = childSnap.val();

          const branch_id = childSnap.key;
          arr.push({
            ...item,
          });
        });

        setItems(arr);
      } else {
        setItems(null);
      }
    });

    return () => ref.off();
  }, []);

  if (!isLoaded(items)) {
    return <Loader />;
  }

  if (isEmpty(items)) {
    <List>
      <ListItem>
        <ListItemText primary={`No cart found`} />
      </ListItem>
    </List>;
  }

  const cartItems =
    !isEmpty(items) &&
    items.map((obj, index) => (
      <>
        <Divider component="li" />

        <ListItem>
          <ListItemText
            primary={`# ${obj.product_id}`}
            secondary={
              <React.Fragment>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  {`${obj.product_name}`}
                </Typography>

                <Typography className={classes.title} color="textPrimary" gutterBottom>
                  {`${obj.product_size}`}
                </Typography>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  @ksh {`${obj.product_price}`} * {`${obj.quantity}`}
                </Typography>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Total @ksh {`${obj.totalPrice}`}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      </>
    ));

  return <List>{cartItems}</List>;
}

export default OrderCartDetails;

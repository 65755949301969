import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { CircularProgress, Button, Grid } from "@material-ui/core";
import * as Yup from "yup";
import UploadButton from "../../../Base/UploadButton";
import useImageHandler from "../../../../Hooks/useImageHandler";
import ProgressBar from "../../../Base/ProgressBarImages";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",

        // color: theme.palette.text.secondary,
        "& .MuiFormControl-root": {
            // width: "80%",
            margin: theme.spacing(1),
        },
    },

    form: {
        maxWidth: 345,
        width: "100%",
        marginLeft: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    submit: {
        margin: theme.spacing(0.5),
    },
    actionButtons: {
        marginTop: theme.spacing(1.5),
        display: "flex",
        justifyContent: "center",
    },
}));

const validationSchema = Yup.object({
    store_name: Yup.string().required("companyName is required"),
    store_description: Yup.string().max(200, "must not exceed 200 characters"),
    email: Yup.string().email("invalid store email address").required(),
});

function CompanyEditFormik(props) {
    const { handleStoreUpdate, setEditCompany, store, loading } = props;
    const classes = useStyles();

    const handleCancel = () => {
        setEditCompany(false);
    };

    const { file, setFile, imageUrl, setImageUrl, imageChangeHandler } =
        useImageHandler();

    return (
        <div className={classes.root}>
            <Formik
                initialValues={{
                    store_name: store.store_name,
                    email: store.email,
                    store_description:
                        store.store_description || "no description",
                }}
                validationSchema={validationSchema}
                onSubmit={(values, actions) =>
                    handleStoreUpdate(values, actions, imageUrl)
                }
            >
                <Form className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Field
                                name="store_name"
                                type="text"
                                label="Store Name"
                                variant="outlined"
                                component={TextField}
                                fullWidth
                            />

                            <Field
                                name="store_description"
                                type="text"
                                label="Store Description"
                                variant="outlined"
                                component={TextField}
                                fullWidth
                            />

                            {/* <Field
                                name="companyWebsite"
                                type="text"
                                label="Store Website"
                                variant="outlined"
                                component={TextField}
                                fullWidth
                            /> */}

                            <Field
                                variant="outlined"
                                component={TextField}
                                fullWidth
                                label="Store Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />

                            {file == null && (
                                <UploadButton
                                    imageChangeHandler={imageChangeHandler}
                                    title={
                                        file == null
                                            ? "Edit Logo"
                                            : "Logo Image Uploaded Successfully"
                                    }
                                />
                            )}

                            {file && (
                                <ProgressBar
                                    file={file}
                                    setFile={setFile}
                                    setImageUrl={setImageUrl}
                                />
                            )}

                            <div className={classes.actionButtons}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                    className={classes.submit}
                                    type="submit"
                                >
                                    Ok
                                    {loading && (
                                        <CircularProgress
                                            size={30}
                                            className={classes.progress}
                                        />
                                    )}
                                </Button>

                                <Button
                                    variant="contained"
                                    color="default"
                                    className={classes.submit}
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </div>
    );
}

CompanyEditFormik.propTypes = {
    classes: PropTypes.object.isRequired,
    handleStoreUpdate: PropTypes.func.isRequired,
    company: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    setEditCompany: PropTypes.func.isRequired,
};

export default CompanyEditFormik;

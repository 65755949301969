import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import rootReducer from './Reducers/rootReducer';
import { getFirebase } from 'react-redux-firebase';
import fbconfig from '../Utils/fb.config';

const initialState = {};

const persistConfig = {
  key: 'root',
  storage: localStorage,
  //   whiteList: [], // will be persisted alone
  // blackList: ['constant'],
};

const middleware = [thunk.withExtraArgument({ getFirebase })];

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));

export const rrfProps = {
  firebase: fbconfig,
  config: {},
  dispatch: store.dispatch,
};

export const persistor = persistStore(store);

export default store;

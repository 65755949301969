import { isEmpty, isLoaded } from 'react-redux-firebase';

import Loader from '../../../../../../../../Base/Loader';
import { Marker } from '@react-google-maps/api';
import React from 'react';
import packageIcon from '../../../../../../../../../box.svg';
import { useSelector } from 'react-redux';

function AssignedListOrderMarker(props) {
  const { panTo } = props;

  const order = useSelector((state) => {
    return state.map.order;
  });

  // get user location on page load
  // TODO change to receiver lat
  React.useEffect(() => {
    if (order != null) {
      panTo({
        lat: order.receiver_lat,
        lng: order.receiver_long,
      });
    } else {
      return 'no order to panTo';
    }
  }, [order]);

  if (!isLoaded(order)) {
    return <Loader />;
  }

  if (isEmpty(order)) {
    return null;
  }

  return (
    <div>
      <Marker
        key={order.order_id}
        position={{
          lat: parseFloat(order.receiver_lat),
          lng: parseFloat(order.receiver_long),
        }}
        icon={{
          url: packageIcon,
          scaledSize: new window.google.maps.Size(30, 30),
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(15, 15),
        }}
      />
    </div>
  );
}

export default AssignedListOrderMarker;

import React, { useState } from "react";
import OrdersTable from "./OrderTables";
import { useDispatch, useSelector } from "react-redux";

function Orders(props) {
    const store_id = useSelector((state) => state.firebase.auth.uid);

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
    });

    const [openPopup, setOpenPopup] = useState(false);
    const [record, setRecord] = useState(null);

    const onDelete = (ids) => {
        // deleted selected drivers
        // handleDelete(ids, job_id);
        console.log(ids);
    };

    // modal for driver data
    const openInPopup = (item) => {
        setRecord(item);
        setOpenPopup(true);
    };

    return (
        <>
            <OrdersTable
                openInPopup={openInPopup}
                onDelete={onDelete}
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
                store_id={store_id}
                {...props}
            />
            {/* {record && (
                <OrdersProfile
                    record={record}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                    setRecord={setRecord}
                />
            )} */}
        </>
    );
}

export default Orders;

import React, { useEffect } from 'react';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import { isLoaded, useFirebaseConnect } from 'react-redux-firebase';

import BranchProductCreate from './BranchProductCreate';
import Link from '@material-ui/core/Link';
import Loader from '../../../../Base/Loader';
import PropTypes from 'prop-types';
import Title from '../../../../Base/Title';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  balance: {
    display: 'flex',
  },
  balanceItem: {
    marginRight: '40px',
    marginTop: '24px',
  },
});

export default function BranchInfo(props) {
  const classes = useStyles();
  const { match } = props;

  let branch = React.useRef({});

  const store_id = useSelector((state) => state.firebase.auth.uid);

  useFirebaseConnect([
    {
      path: `/stores/${store_id}`,
    },
  ]);

  const store = useSelector((state) => {
    return state.firebase.data.stores[store_id];
  });

  useEffect(() => {
    branch.current = store.branches[match.params.job_id];
  }, [store, match]);

  if (!isLoaded(store)) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <div className={classes.toolbar}>
        <Title>Supermarket Branch</Title>

        <BranchProductCreate {...props} />
      </div>
      <div className={classes.balance}>
        <div className={classes.balanceItem}>
          <Typography component="p" variant="h5">
            {`${branch.current.branch_name}`}
          </Typography>
          <Typography color="textSecondary" className={classes.depositContext}>
            Branch Name
          </Typography>
        </div>
        <div className={classes.balanceItem}>
          {branch.current.branch_location !== 'tbd' && (
            <>
              <Typography component="p" variant="h5">
                {`${branch.current.branch_location}`}
              </Typography>
              <Typography color="textSecondary" className={classes.depositContext}>
                Location
              </Typography>
            </>
          )}

          <div>
            {/* <Link
                            color="primary"
                            component={RouterLink}
                            to={`${url}/${match.params.job_id}/branch-details/location`}
                        >
                            Edit Location
                        </Link> */}
          </div>
        </div>
        <div className={classes.balanceItem}>
          <Typography component="p" variant="h5">
            {`${store.store_name}`}
          </Typography>
          <Typography color="textSecondary" className={classes.depositContext}>
            Store
          </Typography>
        </div>
      </div>
    </React.Fragment>
  );
}

BranchInfo.propTypes = {
  branch: PropTypes.object.isRequired,
};

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import formatDate from "../../../../Utils/formatDate";
import CardContent from "@material-ui/core/CardContent";
import EditIcon from "@material-ui/icons/Edit";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import CardActionArea from "@material-ui/core/CardActionArea";
import IconButton from "@material-ui/core/IconButton";
import BankIcon from "@material-ui/icons/AccountBalance";
import SpeedIcon from "@material-ui/icons/Speed";
import { blue, green, pink } from "@material-ui/core/colors";
import { formatRelative } from "date-fns";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },

    depositContext: {
        flex: 1,
    },
    pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
    },
    blue: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
    },
    green: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: green[500],
    },
}));

function OwnerCards(props) {
    const classes = useStyles();
    const {
        company: {
            branches,
            createdAt,
            email,
            owner_Id,
            store_name,
            updateAt,
            logo_url,
        },

        setEditCompany,
    } = props;

    return (
        <>
            <>
                <CardActionArea>
                    <CardHeader
                        onClick={() => setEditCompany(true)}
                        avatar={
                            <Avatar
                                aria-label="recipe"
                                className={classes.green}
                                src={logo_url}
                            />
                        }
                        action={
                            <IconButton aria-label="settings">
                                <EditIcon />
                            </IconButton>
                        }
                        // title={`${first_name} ${last_name}`}
                    />
                    <CardContent>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                        >
                            Branches Count{" "}
                            {props.company?.branches == undefined
                                ? 0
                                : Object.keys(branches).length}
                        </Typography>
                    </CardContent>
                </CardActionArea>

                <CardActionArea>
                    <CardHeader
                        avatar={
                            <Avatar
                                aria-label="recipe"
                                className={classes.green}
                            >
                                <SpeedIcon />
                            </Avatar>
                        }
                        title={"Store Email"}
                        subheader={email}
                    />
                </CardActionArea>
                <CardActionArea>
                    <CardHeader
                        avatar={
                            <Avatar
                                aria-label="recipe"
                                className={classes.blue}
                            >
                                <BankIcon />
                            </Avatar>
                        }
                        title="Store Name"
                        subheader={store_name}
                    />
                </CardActionArea>
                <CardActionArea>
                    <CardHeader
                        avatar={
                            <Avatar
                                aria-label="recipe"
                                className={classes.green}
                            >
                                <SpeedIcon />
                            </Avatar>
                        }
                        title={"Created At"}
                        subheader={`${formatDate(createdAt)} - ${formatRelative(
                            new Date(createdAt),
                            new Date()
                        )}`}
                    />
                </CardActionArea>
            </>
        </>
    );
}

export default OwnerCards;

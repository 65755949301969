import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import OrderCartDetails from '../../../../../Store/Dispatch/map/orderListContainer/orderDetails/orderCartDetails';
import OrderDetailsAppBar from './OrderDetailsAppBar';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    maxHeight: '85vh',
    overflowY: 'scroll',
    height: '85vh',
  },
  inline: {
    display: 'block',
  },
  inlinee: {
    display: 'inline',
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

function OrderDetails(props) {
  /*eslint-disable no-unused-vars */
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  const {
    cartID,
    order_status,
    receiver_address,
    receiver_lat,
    receiver_long,
    order_id,
    time,
    date,
    client_name,
    client_phone,
    receiver_id,
    sender_id,
    sender_name,
    sender_phone,
    sender_lat,
    sender_long,
    rider_id = null,
    delivery_schedule = null,
    rider_lat,
    rider_long,
    rider_email,
    rider_id_number,
    rider_plate,
    rider_image,
    rider_name,
    rider_phone,
    orderID,
  } = useSelector((state) => state.map.order);

  return (
    <div className={classes.root}>
      <OrderDetailsAppBar id={order_id} />
      <List component="nav" aria-label="secondary orderlist orders">
        <ListItem button>
          <ListItemText
            primary={`Order status: ${order_status}`}
            secondary={
              <React.Fragment>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  {date} {time}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <OrderCartDetails cartID={cartID} orderID={orderID} />
        {/* <Divider /> */}
        {/* <ListItem alignItems="flex-start" disableGutters>
                    <ListItemText
                        secondary={
                            <>
                                <TimelineDots
                                    data={{
                                        sender_name,
                                        sender_lat,
                                        sender_long,
                                        receiver_lat,
                                        receiver_long,
                                        client_name,
                                        receiver_address,
                                    }}
                                />
                            </>
                        }
                    />
                </ListItem> */}
        <Divider />
        <ListItem button>
          <ListItemText
            primary={'Client'}
            secondary={
              <React.Fragment>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  {client_name}
                </Typography>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  {client_phone}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemText
            primary={'Merchant'}
            secondary={
              <React.Fragment>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  {sender_name}
                </Typography>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  {sender_phone}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider />
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar src={rider_image} />
          </ListItemAvatar>
          <ListItemText
            primary="Rider"
            secondary={
              <React.Fragment>
                <Typography component="span" variant="body2" className={classes.inlinee} color="textPrimary">
                  {rider_name}
                </Typography>
                {' —'} {rider_phone}
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  {rider_plate}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      </List>
    </div>
  );
}

export default OrderDetails;

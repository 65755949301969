import React from "react";
import AddIcon from "@material-ui/icons/Add";
import SingleFieldModal from "../../../../Base/SingleFIeldModal";
import BrandProductForm from "./BranchProductForm";
import Button from "@material-ui/core/Button";

export function BranchProductCreate(props) {
    return (
        <SingleFieldModal
            title="Create Product"
            render={(open) => (
                <Button
                    edge="end"
                    variant="contained"
                    color="primary"
                    // disabled={!isEmpty(storePresent)}
                    startIcon={<AddIcon />}
                    onClick={open}
                >
                    Create Product
                </Button>
            )}
        >
            <BrandProductForm branch_id={props.match.params.job_id} />
        </SingleFieldModal>
    );
}

export default BranchProductCreate;

import * as Yup from 'yup';

// form stuff
import { Field, Form, Formik } from 'formik';
import { Grid, Typography, makeStyles } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import React from 'react';
import { TextField } from 'formik-material-ui';

const validationSchema = Yup.object({
  product_name: Yup.string().required('companyName is required'),
  product_size: Yup.string().required('product size eg 2kg is required'),
  product_price: Yup.number().required('product price is required'),
  refill_price: Yup.number().required('product refill price is required'),
  product_details: Yup.string().min(10).max(200).required('product details is required'),
  quantity: Yup.number().required('specify product quantity'),
  discount: Yup.number().required('specify product discount'),
});

function BranchProductEdit(props) {
  const { editProduct, product, handleClose } = props;

  return (
    <Formik
      initialValues={{
        product_name: product.value.product_name,
        product_size: product.value.product_size,
        product_price: product.value.product_price,
        product_details:
          product.value?.product_details == undefined
            ? `please provide product details for ${product.value.product_name}`
            : product.value.product_details,
        refill_price: product.value?.refill_price == undefined ? 0 : product.value?.refill_price,
        quantity: product.value?.quantity == undefined ? 100 : product.value?.quantity,
        discount: product.value?.discount == undefined ? 0 : product.value.discount,
      }}
      validationSchema={validationSchema}
      onSubmit={editProduct}
    >
      <Form>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Field name="product_name" type="text" label="Product Name" component={TextField} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <Field name="product_size" type="text" label="Size" component={TextField} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <Field name="product_price" type="number" label="Price" component={TextField} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <Field name="refill_price" type="number" label="Refill Price" component={TextField} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <Field name="product_details" type="text" label="Product Details" component={TextField} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <Field name="quantity" type="number" label="Product Quantity" component={TextField} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <Field name="discount" type="number" label="Product Discount" component={TextField} fullWidth />
          </Grid>
          <Grid item xs={8}>
            <Button variant="contained" color="primary" type="submit" size="medium">
              Ok
            </Button>

            <Button
              style={{
                marginLeft: '15px',
              }}
              variant="contained"
              color="secondary"
              onClick={handleClose}
              size="medium"
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
}

export default BranchProductEdit;

import { CONSTANT_TYPE } from '../types';

const initialState = {
  constant: {},
};

const constant = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANT_TYPE:
      return {
        ...state,
        constant: { ...state.constant, ...action.payload },
      };

    default:
      return state;
  }
};

export default constant;

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';

import BranchHeader from './Branch/BranchHeader';
import Content from '../../../Container/Content';
import Dispatch from './Dispatch/Order';
import MapContainer from './Branch/Location/Map';
import Orders from './Orders/SupermarketOrders/Order';
import Products from './Products/Products';
import React from 'react';
import SimpleBranchTabs from './Tabs/SimpleBranchTabs';
import { setGlobalIds } from '../../../../Redux/Actions/constantActions';

export function BranchDetails(props) {
  const { url, path } = useRouteMatch();
  const { match } = props;
  const dispatch = useDispatch();

  const stores = useSelector((state) => state.firebase.data.stores);
  const store_id = useSelector((state) => state.firebase.auth.uid);
  let branch = stores[store_id].branches[match.params.job_id];

  // setting branch id for use later
  React.useEffect(() => {
    dispatch(
      setGlobalIds({
        job_id: match.params.job_id,
        branch_id: match.params.job_id,
        branch: { ...branch, branch_id: match.params.job_id },
        store_name: stores[store_id].store_name,
      }),
    );
  }, [match.params.job_id]);

  const tabRoutes = [
    {
      label: 'Products',
      value: `${url}/products`,
      to: `${url}/products`,
      component: Products,
    },
    {
      label: 'Branch Location',
      value: `${url}/location`,
      to: `${url}/location`,
      component: MapContainer,
    },

    {
      label: 'Dispatch Orders',
      value: `${url}/dispatch-orders`,
      to: `${url}/dispatch-orders`,
      component: Dispatch,
    },
  ];
  return (
    <>
      <Content>
        <BranchHeader {...props} />
        <SimpleBranchTabs {...props} url={url} tabRoutes={tabRoutes} />
      </Content>
    </>
  );
}

export default BranchDetails;

import {
    SET_ERRORS,
    LOADING_UI,
    CLEAR_ERRORS,
    SET_JOB,
    SET_SUCCESS,
    LOAD_JOBS,
    DELETE_JOB,
    SET_CURRENT_JOB,
    CLEAR_CURRENT_JOB,
    UPDATE_JOB,
    FETCH_SINGLE_JOB,
} from "../types";
import axios from "axios";

export const postAJob = (jobData) => (dispatch) => {
    dispatch({ type: LOADING_UI });

    axios
        .post("/job", jobData)
        .then((res) => {
            dispatch({ type: SET_JOB, payload: res.data });
            dispatch({
                type: SET_SUCCESS,
                payload: `Job ${res.data.title} created successfully`,
            });
            // history.push("/jobs");
            dispatch({ type: CLEAR_ERRORS });
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data,
            });
        });
};

// GET /jobs?industry=1&limit=20
// GET /job?jobStatus=2&hr=1
// GET /jobs?industry=2
// GET /jobs?industry=1&limit=3&nextPage=flsdjfjdfjslfjlksdjfl&jobStatus=2
// GET /jobs?sortBy=created_at:desc //soon

export const loadJobs = (limit = 30, nextPage = "", params) => (dispatch) => {
    //params can be of { industry, jobStatus, hr, }
    dispatch({ type: LOADING_UI });

    console.log(params);

    axios
        .get(`/job?limit=${limit}&nextPage=${nextPage}`, {
            params: { ...params },
        })
        .then((res) => {
            dispatch({ type: LOAD_JOBS, payload: res.data });
            dispatch({
                type: SET_SUCCESS,
                payload: `Jobs loaded sucessfully`,
            });
            // history.push("/jobs");
            dispatch({ type: CLEAR_ERRORS });
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data
                    ? err.response.data
                    : "something went wrong",
            });
        });
};

export const deleteJob = (id) => (dispatch) => {
    dispatch({ type: LOADING_UI });

    axios
        .delete(`/job/${id}`)
        .then((res) => {
            dispatch({ type: DELETE_JOB, payload: res.data.id });
            dispatch({
                type: SET_SUCCESS,
                payload: `Jobs deleted sucessfully`,
            });
            dispatch({ type: CLEAR_ERRORS });
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data
                    ? err.response.data
                    : "something went wrong",
            });
        });
};

export const setCurrentJob = (job) => (dispatch) => {
    dispatch({
        type: SET_CURRENT_JOB,
        payload: job,
    });
};

export const clearCurrentJob = () => (dispatch) => {
    dispatch({
        type: CLEAR_CURRENT_JOB,
    });
};

export const updateJob = (id, job) => (dispatch) => {
    dispatch({ type: LOADING_UI });

    axios
        .patch(`/job/${id}`, job)
        .then((res) => {
            dispatch({ type: UPDATE_JOB, payload: res.data });

            dispatch({
                type: SET_SUCCESS,
                payload: `Jobs Updated sucessfully`,
            });
            dispatch({ type: CLEAR_ERRORS });
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data,
            });
        });
};

export const getSingleJob = (id) => (dispatch) => {
    dispatch({ type: LOADING_UI });

    axios
        .get(`/job/${id}`)
        .then((res) => {
            dispatch({ type: FETCH_SINGLE_JOB, payload: res.data });
            dispatch({
                type: SET_SUCCESS,
                payload: `Jobs fetched sucessfully`,
            });
            dispatch({ type: CLEAR_ERRORS });
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data,
            });
        });
};

import { gasRoutes, superMarketRoutes } from '../routes';

/* eslint-disable eqeqeq*/
// export interface CompanyResponse{
//     name:string;
//     owner_id: number;
// }

// export interface UserResponse {
// 	id: number;
// 	first_name: string;
// 	last_name: string;
// 	email: string;
// 	role: string;
//     company?:CompanyResponse
// }

export default function getDashboardRoutes(role) {
  // TODO make sure not other user type accesses this
  if (role === 'supermarket') {
    return superMarketRoutes;
  } else if (role === 'gas vendor') {
    return gasRoutes;
  } else {
    return [];
  }
}

export function getSidebarRoutes(role) {
  if (role === 'supermarket') {
    return superMarketRoutes;
  } else if (role == 'gas vendor') {
    return gasRoutes;
  } else {
    return [];
  }
}

import {
  CLEAR_CURRENT_ORDER,
  SET_CURRENT_ORDER,
  SHOW_ALL_UNASSIGNED,
  TOGGLE_ORDER_DETAILS,
  UN_ASSIGNED_SELECTED,
} from '../types';

const initialState = {
  toggleDetails: false,
  order: null,
  showAll: false,
  unAssignedSelected: null,
};

export default function mapReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_ORDER_DETAILS:
      return {
        ...state,
        toggleDetails: action.payload,
      };

    case SET_CURRENT_ORDER:
      return {
        ...state,
        order: action.payload,
      };

    case CLEAR_CURRENT_ORDER:
      return {
        ...state,
        order: null,
        toggleDetails: false,
      };

    case SHOW_ALL_UNASSIGNED:
      return {
        ...state,
        showAll: action.payload,
        unAssignedSelected: null,
      };
    case UN_ASSIGNED_SELECTED:
      return {
        ...state,
        unAssignedSelected: action.payload,
        showAll: true,
      };
    default:
      return state;
  }
}

import { isEmpty, isLoaded, useFirebaseConnect } from 'react-redux-firebase';

import Alert from '@material-ui/lab/Alert';
import Loader from '../../../../Base/Loader';
import React from 'react';
import Title from '../../../../Base/Title';
import Typography from '@material-ui/core/Typography';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  depositContext: {
    textAlign: 'center',
  },
});

export default function BranchItemsInfo() {
  const classes = useStyles();

  const store_id = useSelector((state) => state.firebase.auth.uid);
  const branch_id = useSelector((state) => state.constant.constant.branch_id);

  useFirebaseConnect([`/adminOrders/${store_id}/${branch_id}`]);

  const orders = useSelector((state) => {
    return get(state.firebase.ordered, `adminOrders.${store_id}.${branch_id}`);
  });

  if (!isLoaded(orders)) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <Title>
        <Alert severity="info">Total Orders</Alert>
      </Title>
      <Typography color="textSecondary" variant="h3" className={classes.depositContext}>
        {isEmpty(orders) ? 0 : orders.length}
      </Typography>
      <Typography color="textSecondary" className={classes.depositContext}>
        Orders
      </Typography>
      {/* <Button size="small" color="primary">
                Redeem
            </Button> */}
    </React.Fragment>
  );
}

import * as Yup from 'yup';

import { Field, Form, Formik } from 'formik';

import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { TextField } from 'formik-material-ui';

const branchSchema = Yup.object({
  name: Yup.string().required('branch name is required'),
  phone: Yup.string().min(10).max(15).required('phone number is required'),
});

function BranchCreate({ createBranch, handleClose }) {
  return (
    <Formik
      initialValues={{
        name: '',
        phone: '',
      }}
      validationSchema={branchSchema}
      onSubmit={(values, action) => createBranch(values, action, handleClose)}
    >
      <Form>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Field name="name" type="text" label="Branch Name" component={TextField} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <Field name="phone" type="text" label="Branch Phone" component={TextField} fullWidth />
          </Grid>
          <Grid item xs={8}>
            <Button variant="contained" color="primary" type="submit">
              Ok
            </Button>

            <Button
              style={{
                marginLeft: '10px',
              }}
              variant="contained"
              color="secondary"
              onClick={handleClose}
            >
              cancel
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
}

BranchCreate.propTypes = {
  createBranch: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default BranchCreate;

import {
  LOADING_UI,
  LOAD_MORE_SHOPIT_MERCHANT_COMMISSIONS,
  LOAD_SHOPIT_MERCHANT_COMMISSIONS,
  SET_COMMISSION_TRIPS_RIDERS,
  SET_ERRORS,
  SET_SHOPIT_ORDERS,
  SET_SHOPIT_SALES,
  SET_SHOPIT_ORDER_TYPES,
  SET_SHOPIT_STATS,
  SET_RECENT_ORDERS,
  SET_RIDER_STATS,
  SET_SUCCESS,
  SET_STORES_SHOPIT,
} from '../types';

import axios from 'axios';

export function getUnique(arr, val) {
  // arr is an array of items
  // val is the type of value that item has e.g item['type'] can be New Cylinder, online etc
  // the we create an array of all the types and return a set(no duplicates)
  let items = [...new Set(arr.map((item) => item[val] !== undefined && item[val]))];
  return items;
}

function formattedObj(arr) {
  // arr is an array of items
  // val is the type of value that item has e.g item['type'] can be New Cylinder, online etc
  // the we create an obj with all the values initialized to zero
  const obj = {};
  arr.forEach((v, i) => {
    obj[v] = 0;
  });
  return obj;
}

function formattedOrderTypes(order_types, unique_types) {
  // arr is the unique order types
  var output = [];

  order_types.forEach(function (item) {
    var existing = output.filter(function (v, i) {
      return v.day == item.day;
    });

    if (existing.length) {
      // on next loop if we find a similar obj based on key
      // spread what we have currently
      //overwrite with new value
      var existingIndex = output.indexOf(existing[0]);
      let order_type = item['order_type'];
      let order_value = item['order_types_totals'];
      const newObj = {
        ...existing[0],
        [order_type]: order_value,
      };

      // replace with modified obj
      output[existingIndex] = { ...newObj };
    } else {
      // create new obj since first instance it will not exit in output
      // spread the order types initialized to zero
      // overwrite value of a unique order type with actual value

      let order_type = item['order_type'];
      let order_value = item['order_types_totals'];

      const initObj = {
        day: item.day,
        ...formattedObj(unique_types),
        [order_type]: order_value,
      };

      output.push(initObj);
    }
  });
  return output;
}

function start_date_end_date(num_days = 30) {
  let daysback = num_days;
  var today = new Date();

  var priorDate = new Date().setDate(today.getDate() - daysback);
  var start_date = new Date(priorDate).toISOString();

  var tommorrow_date = new Date().setDate(today.getDate() + 1);
  var end_date = new Date(tommorrow_date).toISOString();

  return [start_date, end_date];
}

export const loadStore = () => (dispatch, getState) => {
  dispatch({ type: LOADING_UI });

  const store_id = getState().firebase.auth.uid;

  axios
    .get(`/store/${store_id}`)
    .then((res) => {
      dispatch({ type: SET_STORES_SHOPIT, payload: res.data });
      dispatch({
        type: SET_SUCCESS,
        payload: `Store fetched successfully`,
      });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data,
        });
      } else {
        dispatch({
          type: SET_ERRORS,
          payload: err,
        });
      }
    });
};

// dash board stats
export const fetchDashboardStats =
  (nums = 30, store_id) =>
  (dispatch) => {
    dispatch({ type: LOADING_UI });

    const [start_date, end_date] = start_date_end_date(nums);
    const data = {
      start_date,
      end_date,
      store_id,
    };

    axios
      .post(`/reports/stats`, data)
      .then((res) => {
        dispatch({ type: SET_SHOPIT_STATS, payload: res.data });
        dispatch({
          type: SET_SUCCESS,
          payload: `Stats fetched successfully`,
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: SET_ERRORS,
            payload: err.response.data,
          });
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: err,
          });
        }
      });
  };

// orders per day chart
export const fetchordersPerDayStats =
  (nums = 30, branch_id) =>
  (dispatch) => {
    dispatch({ type: LOADING_UI });

    const [start_date, end_date] = start_date_end_date(nums);
    const data = {
      start_date,
      end_date,
      branch_id,
    };

    axios
      .post(`/reports/ordersPerDay`, data)
      .then((res) => {
        dispatch({ type: SET_SHOPIT_ORDERS, payload: res.data });
        dispatch({
          type: SET_SUCCESS,
          payload: `Orders stats fetched successfully`,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_SHOPIT_ORDERS,
          payload: [
            {
              day: new Date().toISOString(),
              total_orders: 0,
            },
          ],
        });

        if (err.response) {
          dispatch({
            type: SET_ERRORS,
            payload: err.response.data,
          });
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: err,
          });
        }
      });
  };

// recent orders recent per day
export const fetchRecentOrdersPerDayStats =
  (nums = 2, branch_id) =>
  (dispatch) => {
    dispatch({ type: LOADING_UI });

    const [start_date, end_date] = start_date_end_date(nums);
    const data = {
      start_date,
      end_date,
      branch_id,
    };

    axios
      .post(`/reports/recentOrders`, data)
      .then((res) => {
        const orders = res.data.length > 0 ? res.data : null;
        dispatch({ type: SET_RECENT_ORDERS, payload: orders });
        dispatch({
          type: SET_SUCCESS,
          payload: `Recent Orders fetched successfully`,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_RECENT_ORDERS,
          payload: null,
        });

        if (err.response) {
          dispatch({
            type: SET_ERRORS,
            payload: err.response.data,
          });
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: err,
          });
        }
      });
  };

// order types per dat
export const fetchOrderTypes =
  (nums = 30, branch_id) =>
  (dispatch) => {
    dispatch({ type: LOADING_UI });

    const [start_date, end_date] = start_date_end_date(nums);
    const data = {
      start_date,
      end_date,
      branch_id,
    };

    axios
      .post(`/reports/orderTypesPerDay`, data)
      .then((res) => {
        const { order_types } = res.data;

        console.log(JSON.stringify(order_types, null, 3));

        const order_types_unique = getUnique(order_types, 'order_type');
        const output = formattedOrderTypes(order_types, order_types_unique);

        dispatch({ type: SET_SHOPIT_ORDER_TYPES, payload: { output, uniqueTypes: order_types_unique } });
        dispatch({
          type: SET_SUCCESS,
          payload: `Order types stats fetched successfully`,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_SHOPIT_ORDER_TYPES,
          payload: {
            output: [
              {
                day: new Date().toISOString(),
                online_total_orders: 0,
                self_total_orders: 0,
              },
            ],
            uniqueTypes: ['New Cylinder', 'selfcheckout', 'online'],
          },
        });

        if (err.response) {
          dispatch({
            type: SET_ERRORS,
            payload: err.response.data,
          });
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: err,
          });
        }
      });
  };

// sales per bramch
export const fetchsalesLast30DayStats =
  (nums = 30, branch_id) =>
  (dispatch) => {
    dispatch({ type: LOADING_UI });

    const [start_date, end_date] = start_date_end_date(nums);
    const data = {
      start_date,
      end_date,
      branch_id,
    };

    axios
      .post(`/reports/incomeBranches`, data)
      .then((res) => {
        dispatch({ type: SET_SHOPIT_SALES, payload: res.data });
        dispatch({
          type: SET_SUCCESS,
          payload: `Sales stats fetched successfully`,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_SHOPIT_SALES,
          payload: [
            {
              day: new Date().toISOString(),
              total_income: 0,
            },
          ],
        });

        if (err.response) {
          dispatch({
            type: SET_ERRORS,
            payload: err.response.data,
          });
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: err,
          });
        }
      });
  };

// riders commissions
export const commissionFeeByRiders =
  ({ limit = 50, nextPage = '' }) =>
  (dispatch) => {
    dispatch({ type: LOADING_UI });

    console.log('i ranaaan');

    const data = {
      limit,
    };

    axios
      .post(`/reports/commissionFeeByRiders`, data)
      .then((res) => {
        dispatch({
          type: SET_COMMISSION_TRIPS_RIDERS,
          payload: res.data,
        });
        dispatch({
          type: SET_SUCCESS,
          payload: `Riders commissions fetched successfully`,
        });
        // history.push("/jobs");
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          dispatch({
            type: SET_ERRORS,
            payload: err.response.data,
          });
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: err,
          });
        }
      });
  };

// merchant commissions
export const commissionFeeByMerchant =
  ({ limit = 2, nextPage = '' }) =>
  (dispatch) => {
    dispatch({ type: LOADING_UI });

    const data = {
      limit,
    };

    axios
      .post(`/reports/commissionFeeByMerchant`, data)
      .then((res) => {
        dispatch({
          type: LOAD_SHOPIT_MERCHANT_COMMISSIONS,
          payload: res.data,
        });
        dispatch({
          type: SET_SUCCESS,
          payload: `Merchant commissions fetched successfully`,
        });
        // history.push("/jobs");
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          dispatch({
            type: SET_ERRORS,
            payload: err.response.data,
          });
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: err,
          });
        }
      });
  };

// TODO load more
export const LoadMoreCommissionFeeByMerchant =
  ({ limit, nextPage }) =>
  (dispatch) => {
    dispatch({ type: LOADING_UI });

    const data = {
      limit,
      nextPage,
    };

    axios
      .post(`/reports/commissionFeeByMerchant`, data)
      .then((res) => {
        console.log(res.data);
        dispatch({
          type: LOAD_MORE_SHOPIT_MERCHANT_COMMISSIONS,
          payload: res.data,
        });
        dispatch({
          type: SET_SUCCESS,
          payload: `More Merchants fetched successfully`,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          dispatch({
            type: SET_ERRORS,
            payload: err.response.data,
          });
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: err,
          });
        }
      });
  };

// riders reports

export const fetchRiderStats =
  (rider_id, num_days = 30) =>
  (dispatch) => {
    dispatch({ type: LOADING_UI });

    const [start_date, end_date] = start_date_end_date(num_days);
    const data = {
      start_date,
      end_date,
      rider_id,
    };

    axios
      .post(`/reports/riderStats`, data)
      .then((res) => {
        dispatch({ type: SET_RIDER_STATS, payload: res.data });
        dispatch({
          type: SET_SUCCESS,
          payload: `Stats fetched successfully`,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_RIDER_STATS,
          payload: {
            totalOrders: 0,
            totalTrips: 0,
            commission: 0,
          },
        });

        if (err.response) {
          dispatch({
            type: SET_ERRORS,
            payload: err.response.data,
          });
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: err,
          });
        }
      });
  };

import { Avatar, Chip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase';

import CompanyCard from './CompanyCard';
import CompanyEditFormik from './CompanyEditFormik';
import Content from '../../../../Container/Content';
import Loader from '../../../../Base/Loader';
import OwnerCards from './OwnerCards';
import OwnerEditForm from './OwnerEditFormik';
import PersonIcon from '@material-ui/icons/Person';
import Rating from '@material-ui/lab/Rating';
import SummaryCard from '../../../../Base/SummaryCard';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { updateStore } from '../../../../../Redux/Actions/gasActions';
import { updateUserProfile } from '../../../../../Redux/Actions/authActions';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  headerContainer: {
    position: 'relative',
    height: '100px',
  },
  header: {
    display: 'flex',
    position: 'absolute',
    width: 'calc(100%)',
    top: '-70px',
    alignItems: 'flex-end',
    '& > *': {
      margin: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
    },
  },
  spacer: {
    flexGrow: '1',
  },
  avatar: {
    border: `3px solid white`,
    width: theme.spacing(13),
    height: theme.spacing(13),
    boxShadow: theme.shadows[3],
  },
  actionGroup: {
    display: 'flex',
    width: '330px',
    justifyContent: 'space-between',
    marginRight: 0,
  },
  summaryCards: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  summaryCard: {
    margin: theme.spacing(1),
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  tripCard: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
  },

  fixedHeight: {
    height: 300,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

function Profile(props) {
  const classes = useStyles();
  const [editUser, setEditUser] = useState(false);
  const [editCompany, setEditCompany] = useState(false);

  const { store, merchant, updateUserProfile, updateStore, loading } = props;
  /* eslint-disable react-hooks/exhaustive-deps*/

  const handleUserUpdate = (values, actions, imageUrl) => {
    const { merchant_name, phoneNumber } = values;

    const userData = {
      merchant_name: merchant_name,
      image_url: imageUrl ? imageUrl : merchant.image_url,
      phone_number: phoneNumber,
    };

    updateUserProfile(merchant.user_Id, userData);
    setEditUser(false);
  };

  const handleStoreUpdate = (values, actions, imageUrl) => {
    const store_data = {
      ...values,
      logo_url: imageUrl ? imageUrl : store.logo_url ? store.logo_url : '',
    };

    updateStore({ store_id: store.owner_Id, store_data });
    setEditCompany(false);
  };

  if (!isLoaded(merchant) || !isLoaded(store)) {
    return (
      <div className={classes.root}>
        <Content>
          <SummaryCard title={'Fetching data...please wait'} component={<Loader />} />
        </Content>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Content>
        <div className={classes.headerContainer}>
          <div className={classes.header}>
            <Avatar
              alt={`${merchant.merchant_name}`}
              src={`${merchant.image_url}`}
              classes={{
                root: classes.avatar,
                circle: classes.circle,
              }}
            />
            <Typography variant={'h5'}>{`${merchant.merchant_name}`}</Typography>
            <Chip variant={'outlined'} icon={<PersonIcon />} label={merchant.merchant_type} />

            <div className={classes.spacer} />
          </div>
        </div>

        <div className={classes.summaryCards}>
          <>
            {editUser ? (
              <SummaryCard
                title="Edit Info"
                component={
                  <OwnerEditForm
                    user={merchant}
                    loading={loading}
                    setEditUser={setEditUser}
                    handleUserUpdate={handleUserUpdate}
                  />
                }
              />
            ) : (
              <SummaryCard title="Contact Info" component={<OwnerCards user={merchant} setEditUser={setEditUser} />} />
            )}
          </>

          <>
            {editCompany ? (
              <SummaryCard
                title="Edit Store"
                component={
                  <CompanyEditFormik
                    store={store}
                    handleStoreUpdate={handleStoreUpdate}
                    setEditCompany={setEditCompany}
                  />
                }
              />
            ) : (
              <SummaryCard
                title="Store Info"
                component={<CompanyCard company={store} setEditCompany={setEditCompany} />}
              />
            )}
          </>
        </div>
      </Content>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.firebase.auth,
    merchant: get(state.firebase.data, `merchants.${state.firebase.auth.uid}`),

    store: get(state.firebase.data, `gasStores.${state.firebase.auth.uid}`),
    loading: state.ui.loading,
  };
};

const mapActionsToProps = {
  updateUserProfile,
  updateStore,
};

const enhance = compose(
  connect(mapStateToProps, mapActionsToProps),
  firebaseConnect((props) => [`/merchants/${props.auth.uid}`, `/gasStores/${props.auth.uid}`]),
);

export default enhance(Profile);

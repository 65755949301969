import React, { useEffect } from 'react';

import BranchProductCreate from './BranchProductCreate';
import PropTypes from 'prop-types';
import Title from '../../../../Base/Title';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  balance: {
    display: 'flex',
  },
  balanceItem: {
    marginRight: '40px',
    marginTop: '20px',
  },
});

export default function BranchInfo(props) {
  const classes = useStyles();
  const { match } = props;

  const gasStores = useSelector((state) => state.firebase.data.gasStores);
  const store_id = useSelector((state) => state.firebase.auth.uid);
  let branch = React.useRef({});

  useEffect(() => {
    branch.current = gasStores[store_id].branches[match.params.job_id];
  }, [match, gasStores, store_id]);

  return (
    <React.Fragment>
      <div className={classes.toolbar}>
        <Title>Gas Branch</Title>

        <BranchProductCreate {...props} />
      </div>
      <div className={classes.balance}>
        <div className={classes.balanceItem}>
          <Typography component="p" variant="h5">
            {`${branch.current.branch_name}`}
          </Typography>
          <Typography color="textSecondary" className={classes.depositContext}>
            Branch Name
          </Typography>
        </div>
        <div className={classes.balanceItem}>
          {branch.current.branch_location !== 'tbd' && (
            <>
              <Typography component="p" variant="h5">
                {`${branch.current.branch_location}`}
              </Typography>
              <Typography color="textSecondary" className={classes.depositContext}>
                Location
              </Typography>
            </>
          )}
        </div>
        <div className={classes.balanceItem}>
          <Typography component="p" variant="h5">
            {`${gasStores[store_id].store_name}`}
          </Typography>
          <Typography color="textSecondary" className={classes.depositContext}>
            Store
          </Typography>
        </div>
      </div>
    </React.Fragment>
  );
}

BranchInfo.propTypes = {
  branch: PropTypes.object.isRequired,
};

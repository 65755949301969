import React from "react";

// redux
import { connect } from "react-redux";

// form stuff
import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";
import UploadButton from "../../../../Base/UploadButton";
import useImageHandler from "../../../../../Hooks/useImageHandler";
import ProgressBar from "../../../../Base/ProgressBarImages";
import { createNewProduct } from "../../../../../Redux/Actions/productActions";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";

const validationSchema = Yup.object({
    product_name: Yup.string().required("product Name is required"),
    product_id: Yup.string()
        .max(100, "must not exceed 100 characters")
        .required("product sku is required"),
    product_size: Yup.string()
        .max(30, "must not exceed 30 characters")
        .required("product size eg 2kg is required"),
    product_price: Yup.number().required("product price is required"),
    product_details: Yup.string()
        .min(10)
        .max(200)
        .required("product details is required"),
    quantity: Yup.number().required("specify product quantity"),
    discount: Yup.number().required("specify product discount"),
});

function BranchProduct(props) {
    const { handleClose, createNewProduct, branch_id } = props;

    const { file, setFile, imageUrl, setImageUrl, imageChangeHandler } =
        useImageHandler();

    const createProduct = (values, action) => {
        const product = {
            ...values,
            branch_id,
            image_url:
                imageUrl == null
                    ? "https://i.pinimg.com/originals/c1/94/c7/c194c7141911512614e8418c8be92f33.jpg"
                    : imageUrl,
        };
        createNewProduct({ product, closeModal: handleClose });
    };

    return (
        <Formik
            initialValues={{
                product_name: "",
                product_id: "",
                product_size: "",
                product_price: "",
                product_details: "",
                quantity: "",
                discount: 0,
            }}
            validationSchema={validationSchema}
            onSubmit={createProduct}
        >
            <Form>
                <Grid container spacing={4}>
                    <Grid item container xs={6}>
                        <Grid item xs={12}>
                            <Field
                                name="product_name"
                                type="text"
                                label="Product Name"
                                component={TextField}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name="product_id"
                                type="text"
                                label="Product ID"
                                component={TextField}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name="product_size"
                                type="text"
                                label="Size"
                                component={TextField}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name="product_price"
                                type="number"
                                label="Price"
                                component={TextField}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name="product_details"
                                type="text"
                                label="Product Details"
                                component={TextField}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name="quantity"
                                type="number"
                                label="Product Quantity"
                                component={TextField}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name="discount"
                                type="number"
                                label="Product Discount"
                                component={TextField}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid item container xs={6}>
                        <Grid item xs={12}>
                            {imageUrl ? (
                                <img
                                    style={{
                                        width: "256px",
                                        height: "256px",
                                    }}
                                    src={imageUrl}
                                />
                            ) : (
                                <img
                                    style={{
                                        width: "256px",
                                        height: "256px",
                                    }}
                                    src="https://www.ukje.shop/media/catalog/product/cache/aefcd4d8d5c59ba860378cf3cd2e94da/m/i/mintgroene-voetenzak-5-punts-gordel.jpg"
                                />
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            {file == null && (
                                <UploadButton
                                    imageChangeHandler={imageChangeHandler}
                                    title={
                                        file == null
                                            ? "Upload Product Image"
                                            : "Product Image Uploaded Successfully"
                                    }
                                    fullWidth
                                />
                            )}

                            {file && (
                                <ProgressBar
                                    file={file}
                                    setFile={setFile}
                                    setImageUrl={setImageUrl}
                                />
                            )}
                        </Grid>
                    </Grid>

                    <Grid item xs={8}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            size="medium"
                        >
                            Ok
                        </Button>
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    );
}

const mapActionsToProps = {
    createNewProduct,
};

export default connect(null, mapActionsToProps)(BranchProduct);

//constant
export const CONSTANT_TYPE = 'CONSTANT_TYPE';

// auth reducer types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_USER = 'SET_USER';
export const LOADING_USER = 'LOADING_USER';

// UI REDUCER TYPES
export const SET_ERRORS = 'SET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const LOADING_DATA = 'LOADING_DATA';
export const STOP_LOADING_DATA = 'STOP_LOADING_DATA';
export const SET_SUCCESS = 'SET_SUCCESS';

// SHARED TYPES
export const SET_CURRENT = 'SET_CURRENT';
export const CLEAR_CURRENT = 'CLEAR_CURRENT';

//Staff types
export const ADD_STAFF = 'ADD_STAFF';
export const EDIT_STAFF = 'EDIT_STAFF';
export const DELETE_STAFF = 'DELETE_STAFF';
export const SET_STAFF = 'SET_STAFF';
export const LOAD_STAFF = 'LOAD_STAFF';
export const STAFF_ERROR = 'STAFF_ERROR';
export const UPDATE_STAFF = 'UPDATE_STAFF';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

// company types
export const SET_COMPANY_DATA = 'SET_COMPANY_DATA';
export const SET_HR_STATS = 'SET_HR_STATS';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
// job types
export const SET_JOB = 'SET_JOB';
export const POST_JOB = 'POST_JOB';
export const LOAD_JOBS = 'LOAD_JOBS';
export const DELETE_JOB = 'DELETE_JOB';
export const UPDATE_JOB = 'UPDATE_JOB';
export const SET_CURRENT_JOB = 'SET_CURRENT_JOB';
export const CLEAR_CURRENT_JOB = 'CLEAR_CURRENT_JOB';
export const FETCH_SINGLE_JOB = 'FETCH_SINGLE_JOB';
//proposal
export const SET_CURRENT_PROPOSAL = 'SET_CURRENT_PROPOSAL';
export const REJECT_PROPOSAL = 'REJECT_PROPOSAL';
export const BULK_REJECT_PROPOSAL = 'BULK_REJECT_PROPOSAL';
export const LOAD_PROPOSALS = 'LOAD_PROPOSALS';
export const LOAD_PROPOSALS_STATS = 'LOAD_PROPOSALS_STATS';
export const LOAD_MORE_PROPOSALS = 'LOAD_MORE_PROPOSALS';
export const UPDATE_PROPOSAL = 'UPDATE_PROPOSAL';
export const CLEAR_CURRENT_PROPOSAL = 'CLEAR_CURRENT_PROPOSAL';

// application status types
export const LOAD_STATUS = 'LOAD_STATUS';

// company analytics types

// <Industries></Industries>
export const SET_INDUSTRIES = 'SET_INDUSTRIES';

// FREELANCEr
export const SET_FREELANCER_STATS = 'SET_FREELANCER_STATS';
export const SET_FREELANCER_SKILLS = 'SET_FREELANCER_SKILLS';

// search types
export const LOAD_SEARCH_RESULTS = 'LOAD_SEARCH_RESULTS';
export const FILTER_SEARCH_RESULTS = 'FILTER_SEARCH_RESULTS';
export const SET_CURRENT_RESULT = 'SET_CURRENT_RESULT';
export const LOAD_MORE_RESULTS = 'LOAD_MORE_RESULTS';
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';
export const CLEAR_FILTER_RESULTS = 'CLEAR_FILTER_RESULTS';
export const CLEAR_CURRENT_CATEGORY = 'CLEAR_CURRENT_CATEGORY';
export const SET_CURRENT_CATEGORY = 'SET_CURRENT_CATEGORY';

// industy
export const FILTER_INDUSTRY = 'FILTER_INDUSTRY';
export const CLEAR_INDUSTRY = 'CLEAR_INDUSTRY';

// DRIVER ACTIONS
export const CREATE_DRIVER = 'CREATE_DRIVER';
export const LOAD_DRIVER = 'LOAD_DRIVER';
export const SET_CURRENT_DRIVER = 'SET_CURRENT_DRIVER';
export const CLEAR_CURRENT_DRIVER = 'CLEAR_CURRENT_DRIVER';
export const FILTER_DRIVERS = 'FILTER_DRIVERS';
export const CLEAR_FILTER_DRIVERS = 'CLEAR_FILTER_DRIVERS';
export const FIREBASE_AUTH_ERROR = 'FIREBASE_AUTH_ERROR';
export const FIREBASE_AUTH_SUCCESS = 'FIREBASE_AUTH_SUCCESS';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS';
export const PROFILE_DETAIL_SUCCESS = 'PROFILE_DETAIL_SUCCESS';
export const PROFILE_DETAIL_ERROR = 'PROFILE_DETAIL_ERROR';
export const VEHICLE_DETAIL_ERROR = 'VEHICLE_DETAIL_ERROR';
export const VEHICLE_DETAIL_SUCCESS = 'VEHICLE_DETAIL_SUCCESS';

// merchant types
export const SIGNUP_MERCHANT = 'SIGNUP_MERCHANT';
export const LOGIN_MERCHANT = 'LOGIN_MERCHANT';
export const MERCHANT_AUTH_ERROR = 'MERCHANT_AUTH_ERROR';
export const MERCHANT_AUTH_SUCCESS = 'MERCHANT_AUTH_SUCCESS';

export const CREATE_STORE = 'CREATE_STORE';
export const GET_STORE = 'GET_STORE';
export const DELETE_STORE = 'DELETE_STORE';
export const UPDATE_STORE = 'UPDATE_STORE';
export const SET_CURRENT_STORE = 'SET_CURRENT_STORE';

// basket actions
export const ADD_TO_BASKET = 'ADD_TO_BASKET';
export const CLEAR_BASKET = 'CLEAR_BASKET';
export const REMOVE_FROM_BASKET = 'REMOVE_BASKET';
export const SET_CART_ITEMS_DATA = 'SET_CART_ITEMS_DATA';

// map Actions
export const TOGGLE_ORDER_DETAILS = 'TOGGLE_ORDER_DETAILS';
export const CLEAR_CURRENT_ORDER = 'CLEAR_CURRENT_ORDER';
export const SET_CURRENT_ORDER = 'SET_CURRENT_ORDER';
export const SHOW_ALL_UNASSIGNED = 'SHOW_ALL_UNASSIGNED';
export const UN_ASSIGNED_SELECTED = 'UN_ASSIGNED_SELECTED';

// reports
export const LOAD_SHOPIT_MERCHANT_COMMISSIONS = 'LOAD_SHOPIT_MERCHANT_COMMISSIONS';
export const SET_COMMISSION_TRIPS_RIDERS = 'SET_COMMISSION_TRIPS_RIDERS';
export const SET_SHOPIT_ORDERS = 'SET_SHOPIT_ORDERS';
export const SET_SHOPIT_SALES = 'SET_SHOPIT_SALES';
export const SET_SHOPIT_STATS = 'SET_SHOPIT_STATS';
export const SET_RIDER_STATS = 'SET_RIDER_STATS';
export const LOAD_MORE_SHOPIT_MERCHANT_COMMISSIONS = 'LOAD_MORE_SHOPIT_MERCHANT_COMMISSIONS';
export const SET_STORES_SHOPIT = 'SET_STORES_SHOPIT';
export const SET_RECENT_ORDERS = 'SET_RECENT_ORDERS';
export const SET_SHOPIT_ORDER_TYPES = 'SET_SHOPIT_ORDER_TYPES';

import { firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase';

// import BrandProductForm from "./BranchProductForm";
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Loader from '../../../../../Base/Loader';
import PropTypes from 'prop-types';
import React from 'react';
import SingleFieldModal from '../../../../../Base/SingleFIeldModal';
import { Typography } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'block',
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

export function ViewOrder(props) {
  const classes = useStyles();
  const { cart } = props;

  if (!isLoaded(cart)) {
    return <Loader />;
  }

  if (isEmpty(cart)) {
    return null;
  }

  return (
    <SingleFieldModal
      title="Cart Items"
      render={(open) => (
        <Button
          edge="end"
          variant="contained"
          color="primary"
          // disabled={!isEmpty(storePresent)}

          onClick={open}
        >
          view cart
        </Button>
      )}
    >
      <List>
        {cart.map(
          ({
            value: {
              branch_id,
              cartID: cart_id,
              date,
              manufacture,
              product_id,
              product_name,
              product_price,
              product_size,
              quantity,
              time,
            },
          }) => (
            <>
              <ListItem>
                <ListItemText
                  primary={product_name}
                  secondary={
                    <React.Fragment>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        {date} {time}
                      </Typography>

                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        {product_size}
                      </Typography>
                      <Typography className={classes.title} color="textPrimary" gutterBottom>
                        {product_price} * {quantity}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider component="li" />
            </>
          ),
        )}
      </List>
    </SingleFieldModal>
  );
}

const mapActionsToProps = {};

const mapStateToProps = (state, props) => {
  return {
    cart: get(state.firebase.ordered, `shoppingCarts.${props.cartID}`),
  };
};

ViewOrder.propTypes = {
  cartID: PropTypes.string.isRequired,
};

const enhance = compose(
  connect(mapStateToProps, mapActionsToProps),
  firebaseConnect((props) => {
    return [`/shoppingCarts/${props.cartID}`];
  }),
);

export default enhance(ViewOrder);

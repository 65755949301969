import {
  LOAD_MORE_SHOPIT_MERCHANT_COMMISSIONS,
  LOAD_SHOPIT_MERCHANT_COMMISSIONS,
  SET_COMMISSION_TRIPS_RIDERS,
  SET_STORES_SHOPIT,
  SET_RECENT_ORDERS,
  SET_SHOPIT_ORDERS,
  SET_SHOPIT_ORDER_TYPES,
  SET_SHOPIT_STATS,
  SET_RIDER_STATS,
  SET_SHOPIT_SALES,
} from '../types';

const initialState = {
  store: null,
  stats: {
    totalOrders: 0,
    totalIncome: 0,
    loading: true,
  },
  salesStats: {
    sales: [
      {
        day: new Date().toISOString(),
        total_income: 0,
      },
    ],
    loading: true,
  },
  riderStats: {
    totalOrders: null,
    totalTrips: null,
    commission: null,
    loading: true,
  },
  recentOrdersStats: {
    orders: null,
    loading: true,
  },
  ordersStats: {
    orders: [
      {
        day: new Date().toISOString(),
        total_orders: 0,
      },
    ],
    loading: true,
  },
  orderTypesStats: {
    orders: [
      {
        day: new Date().toISOString(),
        online_total_orders: 0,
        self_total_orders: 0,
      },
    ],
    uniqueTypes: ['New Cylinder', 'selfcheckout', 'online'],

    loading: true,
  },
  merchantsCommissions: {
    results: null,
    pageInfo: null,
    loading: true,
  },
  ridersCommissions: {
    results: null,
    pageInfo: null,
    loading: true,
  },
  errors: null,
  severity: null,
  successMessage: null,
  loadingData: false,
};

const reports = (state = initialState, action) => {
  switch (action.type) {
    case SET_STORES_SHOPIT:
      return {
        ...state,
        store: action.payload,
      };
    case SET_SHOPIT_STATS:
      return {
        ...state,
        stats: {
          ...action.payload,
          loading: false,
        },
      };
    case SET_SHOPIT_SALES:
      return {
        ...state,
        salesStats: {
          sales: action.payload,
          loading: false,
        },
      };
    case SET_RIDER_STATS:
      return {
        ...state,
        riderStats: {
          ...action.payload,
          loading: false,
        },
      };
    case SET_RECENT_ORDERS:
      return {
        ...state,
        recentOrdersStats: {
          orders: action.payload,
          loading: false,
        },
      };
    case SET_SHOPIT_ORDERS:
      return {
        ...state,
        ordersStats: {
          orders: action.payload,
          loading: false,
        },
      };

    case SET_SHOPIT_ORDER_TYPES:
      return {
        ...state,
        orderTypesStats: {
          ...state.orderTypesStats,
          orders: action.payload.output,
          uniqueTypes: action.payload.uniqueTypes,
          loading: false,
        },
      };

    case LOAD_SHOPIT_MERCHANT_COMMISSIONS:
      return {
        ...state,
        merchantsCommissions: {
          results: action.payload.results,
          pageInfo: action.payload.pageInfo,
          loading: false,
        },
      };

    case LOAD_MORE_SHOPIT_MERCHANT_COMMISSIONS:
      return {
        ...state,
        merchantsCommissions: {
          results: [...state.merchantsCommissions.results, ...action.payload.results],
          pageInfo: action.payload.pageInfo,
          loading: false,
        },
      };

    case SET_COMMISSION_TRIPS_RIDERS:
      return {
        ...state,
        ridersCommissions: {
          results: action.payload.results,
          pageInfo: action.payload.pageInfo,
          loading: false,
        },
      };

    default:
      return state;
  }
};

export default reports;

import Box from '@material-ui/core/Box';
// import CommissionsTablePerMerchant from './CommissionsTablePerMerchant';
// import CommissionsTablePerRider from './CommissionsTableRider';
import Content from '../../Container/Content';
import Copyright from '../../Base/Copyright';
import Grid from '@material-ui/core/Grid';
import OrderBranchPerDay from './Store/Reports/OrdersBranchPerDay';
import Paper from '@material-ui/core/Paper';
import React, { useState } from 'react';
import ReportsSummaryStats from './Store/Reports/ReportsSummaryStats';
// import SalesChart from './SalesChart';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { loadStore } from '../../../Redux/Actions/reportActions';
import OrderTypesBarChart from './Store/Reports/OrderTypesPerDay';
import RecentOrdersToday from './Store/Reports/RecentOrdersToday';
import RecentOrderDetailPanel from './Store/Reports/RecentOrderDetailPanel';
import { PeopleDialog2 as PeopleDialog } from '../../Base/People/PeopleDialog';
import SalesPerBranch from './Store/Reports/SalesPerBranch';
import { isEmpty, isLoaded } from 'react-redux-firebase';
import Loader from '../../Base/Loader';
import SummaryCard from '../../Base/SummaryCard';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 500,
    paddingBottom: theme.spacing(-0.5),
  },
  balanceCard: {
    height: 200,
  },
}));

function Metric() {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  // const balancePaper = clsx(classes.paper, classes.balanceCard);
  const [openPopup, setOpenPopup] = useState(false);
  const [record, setRecord] = useState(null);

  const dispatch = useDispatch();
  const store = useSelector((state) => state.report.store);

  const openInPopup = React.useCallback((rowData) => {
    setRecord(rowData);
    setOpenPopup(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setRecord(null);
    setOpenPopup(false);
  });

  React.useEffect(() => {
    dispatch(loadStore());
  }, []);

  if (!isLoaded(store)) {
    return (
      <Content>
        <Loader />
      </Content>
    );
  }

  if (isEmpty(store)) {
    return (
      <Content>
        <SummaryCard title="No store found" />
      </Content>
    );
  }

  return (
    <Content>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ReportsSummaryStats />
        </Grid>
        <Grid item xs={12}>
          <Paper className={fixedHeightPaper}>
            {/* Chart  */}

            <SalesPerBranch />
          </Paper>
        </Grid>

        {/* <Grid item xs={12} md={5} lg={4}>
                    <Paper className={balancePaper}>
                     
                    </Paper>
                </Grid> */}
        {/* Chart */}
        <Grid item xs={12}>
          <Paper className={fixedHeightPaper}>
            {/* Chart  */}
            <OrderBranchPerDay />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={fixedHeightPaper}>
            {/* barchart */}
            <OrderTypesBarChart />
          </Paper>
        </Grid>
        {/* ExpensesTable */}
        <Grid item xs={12}>
          {/* <Paper className={fixedHeightPaper}> */}
          {/* ExpensesTable  */}
          <RecentOrdersToday openInPopup={openInPopup} />
        </Grid>
        {/* recent orders cart items modal
         */}
        {record && (
          <PeopleDialog onClose={handleClose} open={openPopup} title="Cart Items" maxWidth="lg">
            <RecentOrderDetailPanel cartID={'365d971a-a111-4c6a-ae0a-358847b6fd3f'} client_id={record.client_id} />
          </PeopleDialog>
        )}
      </Grid>
      <Box pt={4}>
        <Copyright />
      </Box>
    </Content>
  );
}

export default Metric;

import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../Base/Loader';
import React from 'react';
import SummaryCard from '../../../../Base/SummaryCard';
import { fetchsalesLast30DayStats } from '../../../../../Redux/Actions/reportActions';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty, isLoaded } from 'react-redux-firebase';
import Controls from '../../../../Controls';
import ChartSummary from '../../../../Base/Reports/Chart';

const useStyles = makeStyles((theme) => ({
  summaryCards: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  summaryCard: {
    margin: theme.spacing(1),
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  tripCard: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
  },

  fixedHeight: {
    height: 300,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

function SalesPerBranch() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [label, setLabel] = React.useState('Income Per Branch Last 30 days');

  const store_id = useSelector((state) => state.report.store.store_id);
  const store = useSelector((state) => state.report.store);
  const ordersObj = useSelector((state) => {
    return state.report.salesStats;
  });

  const fetchOrders = React.useCallback((e) => {
    setLabel('Income Last 30 days');
    dispatch(fetchsalesLast30DayStats(30, e.target.value));
  }, []);

  const options =
    !isEmpty(store) &&
    store.branches.map((branch) => ({
      name: branch.branch_name,
      value: branch.branch_name,
      id: branch.branch_id,
    }));

  if (!isLoaded(store_id) || !isLoaded(store)) {
    return (
      <div className={classes.summaryCards}>
        <Loader />
      </div>
    );
  }

  if (isEmpty(options) || isEmpty(store_id)) {
    return <SummaryCard title="No data to render" />;
  }

  return (
    <ChartSummary
      data={ordersObj.sales}
      value={'total_income'}
      //   domain={[
      //     0,
      //     Math.max.apply(
      //       Math,
      //       ordersObj.sales.map((o) => parseInt(o.total_income, 10)),
      //     ),
      //   ]}
      date={'day'}
      label={label}
      component={<Controls.Select name={'branch'} label={'select a branch'} options={options} onChange={fetchOrders} />}
      options={true}
      currency={true}
    />
  );
}

export default SalesPerBranch;

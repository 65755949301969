import * as Yup from "yup";

import { Button, CircularProgress, Grid } from "@material-ui/core";
import { Field, Form, Formik } from "formik";

import ProgressBar from "../../../../Base/ProgressBarImages";
import PropTypes from "prop-types";
import React from "react";
import { TextField } from "formik-material-ui";
import UploadButton from "../../../../Base/UploadButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useImageHandler from "../../../../../Hooks/useImageHandler";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",

        // color: theme.palette.text.secondary,
        "& .MuiFormControl-root": {
            // width: "80%",
            margin: theme.spacing(1),
        },
    },

    form: {
        maxWidth: 345,
        width: "100%",
        marginLeft: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    submit: {
        margin: theme.spacing(0.5),
    },
    actionButtons: {
        marginTop: theme.spacing(1.5),
        display: "flex",
        justifyContent: "center",
    },
}));

const validationSchema = Yup.object({
    merchant_name: Yup.string().required("merchant_name is required"),
    phoneNumber: Yup.string()
        .min(10, "must be atleast 10 characters")
        .max(15, "must not exceed 15 characters")
        .required(" Phone Number is required"),
    // email: Yup.string().email("invalid email address").required(),
});

function OwnerEditFormik(props) {
    const { handleUserUpdate, setEditUser, user, loading } = props;
    const classes = useStyles();

    const handleCancel = () => {
        setEditUser(false);
    };

    const { file, setFile, imageUrl, setImageUrl, imageChangeHandler } =
        useImageHandler();

    return (
        <div className={classes.root}>
            <Formik
                initialValues={{
                    merchant_name: user.merchant_name,

                    // email: user.email,
                    phoneNumber: user.phone_number,
                }}
                validationSchema={validationSchema}
                onSubmit={(values, actions) =>
                    handleUserUpdate(values, actions, imageUrl)
                }
            >
                <Form className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Field
                                name="merchant_name"
                                type="text"
                                label="Merchant Name"
                                variant="outlined"
                                component={TextField}
                                fullWidth
                            />

                            {/* <Field
                                name="lastName"
                                type="text"
                                label="Last Name"
                                variant="outlined"
                                component={TextField}
                                fullWidth
                            /> */}

                            <Field
                                name="phoneNumber"
                                type="text"
                                label="PhoneNumber"
                                variant="outlined"
                                component={TextField}
                                fullWidth
                            />

                            {/* <Field
                                variant="outlined"
                                component={TextField}
                                fullWidth
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            /> */}
                            {file == null && (
                                <UploadButton
                                    imageChangeHandler={imageChangeHandler}
                                    title={
                                        file == null
                                            ? "Edit Profile Picture"
                                            : "Logo Image Uploaded Successfully"
                                    }
                                />
                            )}

                            {file && (
                                <ProgressBar
                                    file={file}
                                    setFile={setFile}
                                    setImageUrl={setImageUrl}
                                />
                            )}

                            <div className={classes.actionButtons}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                    className={classes.submit}
                                    type="submit"
                                >
                                    Ok
                                    {loading && (
                                        <CircularProgress
                                            size={30}
                                            className={classes.progress}
                                        />
                                    )}
                                </Button>

                                <Button
                                    variant="contained"
                                    color="default"
                                    className={classes.submit}
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </div>
    );
}

OwnerEditFormik.propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    setEditUser: PropTypes.func.isRequired,
};

export default OwnerEditFormik;

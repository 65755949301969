import React from "react";
import OrderListTab from "./tabsAndList";
import OrderDetails from "./orderDetails";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";

function OrderListContainerTab(props) {
    const toggleDetails = useSelector((state) => state.map.toggleDetails);
    return (
        <>
            {" "}
            {toggleDetails == true ? (
                <OrderDetails />
            ) : (
                <OrderListTab {...props} />
            )}
        </>
    );
}

export default withRouter(OrderListContainerTab);

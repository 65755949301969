import { Link, Route, Switch } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import React from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  appToolbar: {
    marginTop: theme.spacing(3),
  },
}));

export default function SimpleTabs(props) {
  const classes = useStyles();

  const { tabRoutes, url } = props;
  console.log(url);
  return (
    <div className={classes.root}>
      <Route
        path={url}
        render={(history) => (
          <AppBar color="primary" position="static" className={classes.appToolbar}>
            <Tabs value={history.location.pathname} aria-label="simple tabs example">
              {tabRoutes.map((route) => (
                <Tab label={route.label} value={route.value} component={Link} to={route.value} />
              ))}
            </Tabs>
          </AppBar>
        )}
      />

      <Switch>
        {tabRoutes.map(({ component: Component, value }) => (
          <Route path={value}>
            <Component {...props} />
          </Route>
        ))}
      </Switch>
    </div>
  );
}

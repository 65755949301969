import { DeletePopUpDialog as ConfirmDialog } from '../../../../Base/DeleteDialog';
import React, { useState } from 'react';
import { firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase';
import Chip from '@material-ui/core/Chip';
import ActionButtons from './ProductActionButtons';
import Avatar from '@material-ui/core/Avatar';
// mui
import Checkbox from '@material-ui/core/Checkbox';
import Content from '../../../../Container/Content';
import Loader from '../../../../Base/Loader';
import PropTypes from 'prop-types';
// component
import SummaryCard from '../../../../Base/SummaryCard';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import { compose } from 'redux';
// redux
import { connect } from 'react-redux';
import { get } from 'lodash';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTable from '../../../../Base/Table/useTable';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },

  grow: {
    flexGrow: 0.6,
    flexShrink: 0.6,
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
}));

const headCells = [
  {
    id: 'avatar',
    numeric: false,
    disablePadding: true,
    label: '',
  },
  {
    id: '',
    numeric: false,
    disablePadding: true,
    label: 'Product ID',
  },
  {
    id: 'ProductName',
    numeric: false,
    disablePadding: true,
    label: 'Product Name',
  },

  {
    id: 'RefillPrice',
    numeric: false,
    disablePadding: true,
    label: 'Refill Price',
  },
  {
    id: 'ProductPrice',
    numeric: false,
    disablePadding: true,
    label: 'Product Price',
  },
  {
    id: 'Quantity',
    numeric: false,
    disablePadding: true,
    label: 'Product Quantity',
  },
  // { id: "email", numeric: false, disablePadding: false, label: "Email" },
  {
    id: 'ProductSize',
    numeric: false,
    disablePadding: false,
    label: 'Product Size',
  },

  {
    id: 'Actions',
    numeric: false,
    disablePadding: true,
    label: 'Actions',
  },
];

function ProductsTable(props) {
  const classes = useStyles();

  const { products, openInPopup, onDelete, confirmDialog, setConfirmDialog } = props;

  /* eslint-disable no-unused-vars */
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const { selected, setSelected, selectTableRow, TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(products, products, headCells, filterFn);

  const [snackOpen, setSnackOpen] = React.useState(false);

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const snackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpen(false);
  };

  if (!isLoaded(products)) {
    return <Loader />;
  }

  if (isEmpty(products)) {
    return (
      <Content>
        <SummaryCard title="No products for branch found" />;
      </Content>
    );
  }

  return (
    <>
      <div className={classes.root}>
        <Toolbar>
          <div edge="start" className={classes.grow} /> <div edge="start" className={classes.grow} />
          {/* {selected.length > 0 && (
                        <div>
                            <DeletePeopleDialog
                                ids={selected}
                                onSave={() => {
                                    // delete product
                                    onDelete(selected);

                                    setSnackOpen(
                                        `${selected.length} Product${
                                            selected.length > 1 ? "s" : ""
                                        } Deleted`
                                    );
                                    setSelected([]);
                                }}
                                render={(open) => (
                                    <Button
                                        className={classes.deleteButton}
                                        variant="contained"
                                        color="secondary"
                                        startIcon={<DeleteIcon />}
                                        onClick={open}
                                    >
                                        {" "}
                                        Delete {selected.length} selected
                                    </Button>
                                )}
                            />
                        </div>
                    )} */}
        </Toolbar>
        <SummaryCard
          title={'Products'}
          value={
            <>
              <TblContainer>
                <TblHead />

                <TableBody>
                  {recordsAfterPagingAndSorting().map((row, index) => {
                    const isItemSelected = isSelected(row.key);
                    const labelId = `enhanced-table-checkbox-${row.key}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        onClick={(e) => {
                          if (e.target.type === 'checkbox' || e.target.className.indexOf('Checkbox') > 0) {
                            return;
                          }
                        }}
                        key={`product-${row.key}`}
                        selected={isItemSelected}
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        <TableCell
                          padding="checkbox"
                          onClick={(e) => {
                            selectTableRow(row.key);
                          }}
                        >
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                            onChange={(e) => {
                              selectTableRow(row.key);
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Avatar alt={row.value.name} src={row.value.image_url} />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          // padding="none"
                        >
                          {row.key}
                        </TableCell>
                        <TableCell align="left">{row.value.product_name}</TableCell>
                        {/* <TableCell scope="row" padding="none">
                          {row.value.product_id}
                        </TableCell> */}
                        <TableCell>ksh {row.value?.refill_price}</TableCell>
                        <TableCell>ksh {row.value.product_price}</TableCell>
                        <TableCell>
                          {row.value?.quantity > 0 ? (
                            row.value?.quantity
                          ) : (
                            <Chip label="Please Restock" color="secondary" />
                          )}
                        </TableCell>
                        <TableCell>{row.value.product_size}</TableCell>

                        <TableCell>
                          <ActionButtons product={row} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </TblContainer>
              <TblPagination />
            </>
          }
        />

        <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
      </div>
    </>
  );
}

const mapStateToProps = (state, props) => {
  return {
    products: get(state.firebase.ordered, `products.${props.match.params.job_id}`),
  };
};

const mapActionsToProps = {};

ProductsTable.propTypes = {
  ui: PropTypes.object.isRequired,
  openInPopup: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const enhance = compose(
  connect(mapStateToProps, mapActionsToProps),
  firebaseConnect((props) => [`/products/${props.match.params.job_id}`]),
);

export default enhance(ProductsTable);

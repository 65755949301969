import * as Yup from 'yup';

import React, { useEffect } from 'react';
import SingleFieldModal, { SingleFieldForm } from '../../../Base/SingleFIeldModal';
import { firebaseConnect, isLoaded } from 'react-redux-firebase';

import AddIcon from '@material-ui/icons/Add';
import BranchCard from './BranchCard';
import BranchCreate from './Branch/BranchCreate';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Content from '../../../Container/Content';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import SummaryCard from '../../../Base/SummaryCard';
import { compose } from 'redux';
// redux
import { connect } from 'react-redux';
import { createNewBranch } from '../../../../Redux/Actions/gasActions';
import { createNewStore } from '../../../../Redux/Actions/storeActions';
import { deleteJob } from '../../../../Redux/Actions/jobActions';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch } from 'react-router';

const storeSchema = Yup.object({
  name: Yup.string().required('store name is required'),
});

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    position: 'relative',
    height: '100px',
    marginTop: theme.spacing(4),
  },
  header: {
    display: 'flex',
    position: 'absolute',
    width: 'calc(100%)',
    top: '-70px',
    alignItems: 'flex-end',
    '& > *': {
      margin: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
    },
  },
  spacer: {
    flexGrow: '1',
  },
  avatar: {
    border: `3px solid white`,
    width: theme.spacing(13),
    height: theme.spacing(13),
    boxShadow: theme.shadows[3],
  },
  actionGroup: {
    display: 'flex',
    width: '330px',
    justifyContent: 'space-between',
    marginRight: 0,
  },
  summaryCards: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  summaryCard: {
    margin: theme.spacing(1),
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  tripCard: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
  },
}));

export function Gas(props) {
  const classes = useStyles();

  const { url } = useRouteMatch();

  const {
    deleteJob,
    createNewStore,
    createNewBranch,
    auth,
    storePresent,
    ui: { loading },
  } = props;

  const createStore = (values, action, handleClose) => {
    const store = {
      store_name: values.name,
      owner_id: auth.uid,
    };

    createNewStore({ store, closeModal: handleClose });
  };

  const createBranch = async (values, action, handleClose) => {
    const branch = {
      store_id: auth.uid,
      store_name: storePresent.store_name,
      branch_name: values.name,
      branch_lat: storePresent.lat + '',
      branch_lng: storePresent.long + '',
      branch_location: 'tbd',
      branch_phone: values.phone,
    };

    createNewBranch({ branch, closeModal: handleClose });
  };

  if (!isLoaded(storePresent) || loading) {
    return (
      <Content>
        <Grid container justify="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      </Content>
    );
  }

  return (
    <Content>
      <div className={classes.headerContainer}>
        <div className={classes.header}>
          <div className={classes.actionGroup}>
            <SingleFieldModal
              title="Create Store"
              render={(open) => (
                <Button color="secondary" variant="contained" disabled={true} startIcon={<AddIcon />} onClick={open}>
                  Create Store
                </Button>
              )}
            >
              <SingleFieldForm
                label="Store Name"
                handleSubmit={createStore}
                validationSchema={storeSchema}
              ></SingleFieldForm>
            </SingleFieldModal>
          </div>
          <div className={classes.spacer} />
          <div className={classes.actionGroup}>
            <SingleFieldModal
              title="Create Branch"
              render={(open) => (
                <Button
                  color="primary"
                  variant="contained"
                  // disabled={isEmpty(storePresent)}
                  startIcon={<AddIcon />}
                  onClick={open}
                >
                  Create Branch
                </Button>
              )}
            >
              <BranchCreate createBranch={createBranch} />
            </SingleFieldModal>
          </div>
        </div>
      </div>
      <Grid container spacing={2}>
        {storePresent.branches ? (
          Object.keys(storePresent.branches).map((key, index) => (
            <Grid item xs={12} sm={12} md={4} lg={4} key={index}>
              <BranchCard
                url={url}
                branch={{
                  ...storePresent.branches[key],
                  branch_id: key,
                }}
                deleteJob={deleteJob}
                // userRole={user.role}
              />
            </Grid>
          ))
        ) : (
          <>
            <SummaryCard title="You dont have any gas branches, please create one" />
          </>
        )}
      </Grid>
    </Content>
  );
}

Gas.propTypes = {
  auth: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  jobs: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    auth: state.firebase.auth,
    ui: state.ui,
    jobs: state.jobs,
    storePresent: get(state.firebase.data.gasStores, `${state.firebase.auth.uid}`),
  };
};

const mapActionsToProps = {
  deleteJob,
  createNewStore,
  createNewBranch,
};

const enhance = compose(
  connect(mapStateToProps, mapActionsToProps),
  firebaseConnect((props) => [
    {
      path: `/gasStores/${props.auth.uid}`,
    },
  ]),
);

export default enhance(Gas);

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import React from 'react';
import SetStoreLocation from './SetStoreLocation';
import Typography from '@material-ui/core/Typography';
import { formatRelative } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
});

export default function BranchMarkerInfoCard({ branch, marker, store, id, store_id }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            store: {store.store_name}
          </Typography>
          <Typography gutterBottom variant="h5" component="h2">
            branch: {branch.branch_name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            created: {formatRelative(new Date(store.createdAt), new Date())}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        {/* set store location */}
        <SetStoreLocation marker={marker} branch={branch} id={id} store_id={store_id} />
      </CardActions>
    </Card>
  );
}

import React from 'react';
// redux related
import { useDispatch, useSelector } from 'react-redux';
import ChartSummary from '../../../../Base/Reports/Chart';
import Loader from '../../../../Base/Loader';
import VerticonOptions from '../../../../Base/VerticonOptions';
import { fetchordersPerDayStats } from '../../../../../Redux/Actions/reportActions';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty, isLoaded } from 'react-redux-firebase';
import Controls from '../../../../Controls';
import SummaryCard from '../../../../Base/SummaryCard';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  headerContainer: {
    position: 'relative',
    height: '100px',
  },
  header: {
    display: 'flex',
    position: 'absolute',
    width: 'calc(100%)',
    top: '-70px',
    alignItems: 'flex-end',
    '& > *': {
      margin: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
    },
  },
  spacer: {
    flexGrow: '1',
  },
  avatar: {
    border: `3px solid white`,
    width: theme.spacing(13),
    height: theme.spacing(13),
    boxShadow: theme.shadows[3],
  },
  actionGroup: {
    display: 'flex',
    width: '330px',
    justifyContent: 'space-between',
    marginRight: 0,
  },
  summaryCards: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  summaryCard: {
    margin: theme.spacing(1),
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  tripCard: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
  },

  fixedHeight: {
    height: 300,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

function OrderChart() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [label, setLabel] = React.useState('Orders Per Branch last 30 days');

  const store = useSelector((state) => state.report.store);
  const ordersObj = useSelector((state) => {
    return state.report.ordersStats;
  });

  const fetchOrders = React.useCallback((e) => {
    setLabel('Orders last 30 days');
    dispatch(fetchordersPerDayStats(30, e.target.value));
  }, []);

  const options =
    !isEmpty(store) &&
    store.branches.map((branch) => ({
      name: branch.branch_name,
      value: branch.branch_name,
      id: branch.branch_id,
    }));

  if (!isLoaded(store)) {
    return (
      <div className={classes.summaryCards}>
        <Loader />
      </div>
    );
  }

  if (isEmpty(options)) {
    return <SummaryCard title="No data to render" />;
  }

  return (
    <ChartSummary
      data={ordersObj.orders}
      value={'total_orders'}
      // domain={[
      //   0,
      //   Math.max.apply(
      //     Math,
      //     ordersObj.orders.map((o) => parseInt(o.total_orders, 10)),
      //   ),
      // ]}
      date={'day'}
      label={label}
      component={<Controls.Select name={'branch'} label={'select a branch'} options={options} onChange={fetchOrders} />}
      options={true}
    />
  );
}

export default OrderChart;

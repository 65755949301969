import * as Yup from "yup";

import { FormikStep, FormikStepper } from "../RegisterWizard/FormikStepper";
import React, { useEffect, useState } from "react";
import { errorAlert, setSuccess } from "../../../../../Redux/Actions/uiActions";

//mui stuff
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Content from "../../../../Container/Content";
import Controls from "../../../../Controls";
// components
import Copyright from "../../../../Base/Copyright";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Field } from "formik";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Loader from "../../../../Base/Loader";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";
import ProgressBar from "../../../../Base/ProgressBarImages";
import PropTypes from "prop-types";
import SummaryCard from "../../../../Base/SummaryCard";
import { TextField } from "formik-material-ui";
import Typography from "@material-ui/core/Typography";
import UploadButton from "../../../../Base/UploadButton";
// redux
import { connect } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { registerUser } from "../../../../../Redux/Actions/authActions";
import useImageHandler from "../../../../../Hooks/useImageHandler";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
        "& .MuiFormControl-root": {
            width: "80%",
            margin: theme.spacing(1),
        },
    },
    image: {
        backgroundRepeat: "no-repeat",
        backgroundColor:
            theme.palette.type === "dark"
                ? theme.palette.grey[900]
                : theme.palette.grey[50],
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
        paddingTop: "40px",
    },
    paper: {
        margin: theme.spacing(8, 8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    imageUrl: {
        height: "80px",
        width: "80px",
        margin: theme.spacing(1),
        // backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    progress: {
        position: "absolute",
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },

    summaryCards: {
        display: "flex",
        flexWrap: "wrap",
    },
    summaryCard: {
        margin: theme.spacing(1),
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

const validationSchemaOwner = Yup.object({
    email: Yup.string().email("invalid email address").required(),
    password: Yup.string()
        .min(8)
        .max(200)
        .matches(/[^A-Za-z0-9]/, "password must contain a special character")
        .matches(/[A-Z]/, "password must contain an uppercase letter")
        .matches(/[a-z]/, "password must contain a lowercase letter")
        .matches(/[0-9]/, "password must contain a number")
        .required(),
    confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
    ),
});

const validationSchemaUser = Yup.object({
    merchant_name: Yup.string().required("firstName is required"),
    phone_number: Yup.string()
        .min(10, "must be atleast 10 characters")
        .max(15, "must not exceed 15 characters")
        .required(" Phone Number is required"),
    kra_pin: Yup.string()
        .min(11, "must be atleast 11 characters")
        .max(11, "must not exceed 11 characters")
        .required("kra pin is required"),
});

const validationSchemaMerchant = Yup.object({
    merchant_type: Yup.string().required("Merchant Type is required"),
});

const merchantTypes = [
    { value: "gas vendor", id: "gas vendor" },
    { value: "supermarket", id: "supermarket" },
];

const Register = (props) => {
    const classes = useStyles();
    const {
        ui: { loading },
        registerUser,
        setSuccess,
        auth: { authenticated },
    } = props;

    const { file, setFile, imageUrl, setImageUrl, imageChangeHandler } =
        useImageHandler();
    const [location, setLocation] = useState({
        lat: 0,
        long: 0,
    });

    useEffect(() => {
        if (authenticated) {
            props.history.push("/dashboard");
        }
    }, [authenticated, props.history]);

    // get user location
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setLocation({
                    lat: "" + position.coords.latitude,
                    long: "" + position.coords.longitude,
                });
                setSuccess("location set successfully");
                console.log(location);
            },
            () => {
                errorAlert("uh..oh, no location provided");
                console.log("uh..oh, no location provided");
                // denied geolocation so we go for ip
                fetch("https://ipapi.co/json")
                    .then((res) => res.json())
                    .then((location) => {
                        setLocation({
                            lat: "" + location.latitude,
                            long: "" + location.longitude,
                        });
                        setSuccess("location set successfully 2");
                    })
                    .catch((error) => {
                        console.log(error);
                        errorAlert(error);
                    });
            }
        );
    }, [location, setSuccess]);

    const handleSubmit = (values, actions) => {
        console.log("clicked.....................");
        const {
            merchant_name,
            kra_pin,
            email,
            phone_number,
            password,
            merchant_type,
        } = values;

        const user = {
            merchant_name,
            kra_pin,
            email,
            phone_number,
            password,
            image_url:
                imageUrl == null
                    ? "https://i.pinimg.com/originals/c1/94/c7/c194c7141911512614e8418c8be92f33.jpg"
                    : imageUrl,
            merchant_type,
            ...location,
        };

        registerUser(user, props.history);
        actions.resetForm();
        setImageUrl(null);
    };

    if (loading) {
        return (
            <Content>
                <>
                    <SummaryCard
                        title="Please wait as we create an account for you"
                        component={<Loader />}
                    />
                </>
            </Content>
        );
    }

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid container justify="center" className={classes.image}>
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={5}
                    component={Paper}
                    direction="row"
                    elevation={6}
                    square
                >
                    <Grid className={classes.paper}>
                        {imageUrl ? (
                            <Avatar
                                className={classes.imageUrl}
                                src={imageUrl}
                            />
                        ) : (
                            <Avatar className={classes.avatar}>
                                <LockOutlinedIcon />
                            </Avatar>
                        )}
                        <Typography component="h1" variant="h5">
                            Create Merchant Account
                        </Typography>

                        <FormikStepper
                            initialValues={{
                                merchant_name: "",
                                email: "",
                                phone_number: "",
                                kra_pin: "",
                                password: "",
                                confirmPassword: "",
                            }}
                            onSubmit={handleSubmit}
                            className={classes.form}
                        >
                            <FormikStep
                                validationSchema={validationSchemaOwner}
                                label="Auth Details"
                            >
                                <Field
                                    variant="outlined"
                                    component={TextField}
                                    fullWidth
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    margin="normal"
                                />

                                <Field
                                    variant="outlined"
                                    type="password"
                                    component={TextField}
                                    fullWidth
                                    label="Password"
                                    name="password"
                                    margin="normal"
                                />

                                <Field
                                    name="confirmPassword"
                                    type="password"
                                    label="Confirm Password"
                                    component={TextField}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                />

                                {/* <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                    className={classes.submit}
                                    type="submit"
                                >
                                    Create Account
                                    {loading && (
                                        <CircularProgress
                                            size={30}
                                            className={classes.progress}
                                        />
                                    )}
                                </Button> */}
                            </FormikStep>

                            <FormikStep
                                label="User Details"
                                validationSchema={validationSchemaUser}
                            >
                                <Field
                                    name="merchant_name"
                                    type="text"
                                    label="Merchant Name"
                                    variant="outlined"
                                    component={TextField}
                                    fullWidth
                                    margin="normal"
                                />

                                <Field
                                    name="phone_number"
                                    type="text"
                                    label="Phone Number"
                                    variant="outlined"
                                    component={TextField}
                                    fullWidth
                                    margin="normal"
                                />

                                <Field
                                    name="kra_pin"
                                    type="text"
                                    label="Kra Pin"
                                    variant="outlined"
                                    component={TextField}
                                    fullWidth
                                    margin="normal"
                                />
                            </FormikStep>

                            <FormikStep
                                label="Merchant Type"
                                validationSchema={validationSchemaMerchant}
                            >
                                <Field
                                    as={Controls.Select}
                                    options={merchantTypes}
                                    name="merchant_type"
                                    type="select"
                                    label="Merchant Type"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                />

                                {file == null && (
                                    <UploadButton
                                        imageChangeHandler={imageChangeHandler}
                                        title={
                                            file == null
                                                ? "Upload Profile Image"
                                                : "Profile Image Uploaded Successfully"
                                        }
                                        fullWidth
                                    />
                                )}

                                {file && (
                                    <ProgressBar
                                        file={file}
                                        setFile={setFile}
                                        setImageUrl={setImageUrl}
                                    />
                                )}
                            </FormikStep>
                        </FormikStepper>

                        <Grid container mt={5}>
                            <Grid item xs>
                                <Link to="/forgot-password" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link to="/login" variant="body2">
                                    {"Already have an account? Log in"}
                                </Link>
                            </Grid>
                        </Grid>
                        <Box mt={5}>
                            <Copyright />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

Register.propTypes = {
    registerUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    ui: state.ui,
});

const mapActionsToProps = {
    registerUser,
    errorAlert,
    setSuccess,
};

export default connect(mapStateToProps, mapActionsToProps)(Register);

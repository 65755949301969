import React from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Title from '../Title';
import { format, parseISO } from 'date-fns';

const fillArray = ['#002884', '#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#03a9f4', '#4caf50', '#ffc107'];

export default function BarChartSummary({ data, types, date, label, currency = false, options, component }) {
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Title>{label}</Title>
        {options && component}
      </div>
      <ResponsiveContainer
        width="100%"
        height={400}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <BarChart
          //   width={500}
          //   height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={date}
            axisLine={false}
            tickLine={false}
            tickFormatter={(str) => {
              const date = parseISO(str.substr(0, 10));
              return format(date, 'MMM, d');
            }}
          />
          <YAxis tickFormatter={(number) => `${Number(number).toFixed(0)}`} />
          <Tooltip />
          <Legend />

          {types.length > 0 &&
            types.map((item, i) => (
              <Bar dataKey={item} fill={`${fillArray[Math.floor(Math.random() * 9)]}`} stackId="a" key={i} />
            ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

import moment from 'moment';
export default function formatDate(someDate) {
  return moment(someDate).format('MMM Do YY');
}

export function formatCash(someCash) {
  return new Intl.NumberFormat('en-ke', {
    style: 'currency',
    currency: 'ksh',
  }).format(someCash);
}

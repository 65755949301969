import MuiTable from 'material-table';
import React from 'react';

function MaterialTable({ title, columns, data, actions, options, ...rest }) {
  return (
    <MuiTable
      title={title}
      columns={columns}
      data={data}
      actions={actions}
      options={{
        pageSizeOptions: [5, 10, 25, 50, 100],
        pageSize: 10,
        exportAllData: true,
        exportButton: true,
        filtering: true,
        searchAutoFocus: false,
        sorting: true,
        ...options,
      }}
      {...rest}
    />
  );
}

export default MaterialTable;

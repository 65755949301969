import { ADD_TO_BASKET, CLEAR_BASKET, REMOVE_FROM_BASKET, SET_CART_ITEMS_DATA } from '../types';

const initialState = {
  orders: [],
  shoppingCarts: null,
  orderMap: {}, // will hold keys for the orders to prevent duplications
};

export default function basketReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TO_BASKET:
      state.orderMap[action.payload.order_id] = action.payload;
      return {
        ...state,
        orders: [...state.orders, action.payload],
      };

    case CLEAR_BASKET:
      return {
        orders: [],
        orderMap: {},
      };

    case SET_CART_ITEMS_DATA:
      return {
        ...state,
        shoppingCarts: action.payload,
      };

    case REMOVE_FROM_BASKET:
      const id = action.payload;
      delete state.orderMap[id];
      return {
        ...state,
        orders: [
          ...state.orders.filter((order) => {
            return order.order_id !== id;
          }),
        ],
      };

    default:
      return state;
  }
}

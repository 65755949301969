import React from "react";
import { Route } from "react-router-dom";
export default function JobsContainer(props) {
    const { url } = props.match;
    const { routes } = props;

    return (
        <div>
            {routes.map(({ path, component, ...rest }, index) => (
                <Route
                    path={`${url}/${path}`}
                    component={component}
                    {...rest}
                    key={`${path}${index}`}
                />
            ))}
        </div>
    );
}

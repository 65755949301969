import '@reach/combobox/styles.css';

import { GoogleMap, useLoadScript } from '@react-google-maps/api';

import AssignedListOrderMarker from './orderListContainer/tabsAndList/orderLists/AssignedListOrderMarker';
import { Grid } from '@material-ui/core';
import Loader from '../../../../../Base/Loader';
import OrderListContainer from './orderListContainer/OrderListContainer';
import OrderMarkers from './orderMarkers/OrderMarkers';
import React from 'react';
import RiderMarkers from './orderMarkers/riderMarkers';
import Search from './Search';
import { errorAlert } from '../../../../../../Redux/Actions/uiActions';
import { useDispatch } from 'react-redux';

const style = {
  width: '100%',
  height: '85vh',
  position: 'relative',
  maxHeight: '85vh',
  overflowY: 'scroll',
};

const center = {
  lat: -1.292066,
  lng: 36.821945,
};

const options = {
  // styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
};

// to avoid re-renders
const libraries = ['places'];

const MapContainer = (props) => {
  const dispatch = useDispatch();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  /*eslint-disable eqeqeq */

  // get map ref
  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  // move map to a specific location
  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat: parseFloat(lat), lng: parseFloat(lng) });
    mapRef.current.setZoom(14);
  }, []);

  // get user location on page load
  // React.useEffect(() => {
  //     navigator.geolocation.getCurrentPosition(
  //         (position) => {
  //             panTo({
  //                 lat: position.coords.latitude,
  //                 lng: position.coords.longitude,
  //             });
  //         },
  //         () => {
  //             console.log("uh..oh, no location provided");
  //             // denied geolocation so we go for ip
  //             fetch("https://ipapi.co/json")
  //                 .then((res) => res.json())
  //                 .then((location) => {
  //                     panTo({
  //                         lat: location.latitude,
  //                         lng: location.longitude,
  //                     });
  //                 })
  //                 .catch((error) =>
  //                     console.log(error, "====================")
  //                 );
  //         }
  //     );
  // }, []);

  if (loadError) {
    return dispatch(errorAlert({ message: 'Error loading maps' }));
  }

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <div
      style={{
        maxHeight: '85vh',
        position: 'relative',
      }}
    >
      <Search center={center} panTo={panTo} />
      <Grid container>
        <Grid item xs={3} spacing={2}>
          <OrderListContainer {...props} />
        </Grid>

        <Grid item xs={9}>
          <GoogleMap
            mapContainerStyle={style}
            zoom={11}
            center={center}
            options={options}
            // onClick={onMapClick}
            onLoad={onMapLoad}
          >
            {isLoaded && <OrderMarkers panTo={panTo} />}
            {isLoaded && <AssignedListOrderMarker panTo={panTo} />}
            {isLoaded && <RiderMarkers />}
          </GoogleMap>
        </Grid>
      </Grid>
    </div>
  );
};

export default MapContainer;

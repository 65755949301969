import { InfoWindow, Marker } from '@react-google-maps/api';
import { isEmpty, isLoaded, useFirebaseConnect } from 'react-redux-firebase';

import Loader from '../../../../../../../Base/Loader';
import React from 'react';
import RiderInfoCard from './RiderInfoCard';
import riderIcon from '../../../../../../../../delivery-man.svg';
import { useSelector } from 'react-redux';

// <img src="https://img.icons8.com/emoji/48/000000/package-.png"/>
function OrderMarkers(props) {
  const [selected, setSelected] = React.useState(null);
  useFirebaseConnect(['All Riders']);

  const riders = useSelector((state) => {
    return state.firebase.ordered && state.firebase.ordered['All Riders'];
  });

  if (!isLoaded(riders)) {
    return <Loader />;
  }

  if (isEmpty(riders)) {
    return null;
  }

  return (
    <div>
      {riders.map((rider) => (
        <Marker
          key={rider.key}
          position={{
            lat: rider.value.rider_lat,
            lng: rider.value.rider_long,
          }}
          icon={{
            url: riderIcon,
            scaledSize: new window.google.maps.Size(30, 30),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(15, 15),
          }}
          onClick={() => {
            setSelected(rider);
            console.log('clicked');
          }}
        />
      ))}

      {/* render info */}
      {selected ? (
        <InfoWindow
          position={{
            lat: selected.value.rider_lat,
            lng: selected.value.rider_long,
          }}
          onCloseClick={() => {
            setSelected(null);
          }}
        >
          <RiderInfoCard rider={selected} />
        </InfoWindow>
      ) : null}
    </div>
  );
}

export default OrderMarkers;

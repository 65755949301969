// redux related
import { useDispatch, useSelector } from 'react-redux';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import Loader from '../../../../Base/Loader';
import React from 'react';
import SummaryCard from '../../../../Base/SummaryCard';
import VerticonOptions from '../../../../Base/VerticonOptions';
import { fetchDashboardStats } from '../../../../../Redux/Actions/reportActions';
import { formatCash } from '../../../../../Utils/formatDate';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  summaryCards: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  summaryCard: {
    margin: theme.spacing(1),
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  tripCard: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
  },

  fixedHeight: {
    height: 300,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

function ReportsSummaryStats() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [label, setLabel] = React.useState({
    c: 'Store Income Last 30days',
    o: 'Store Orders Last 30days',
  });

  const statsObj = useSelector((state) => {
    return state.report.stats;
  });

  const store_id = useSelector((state) => {
    return state.report.store.store_id;
  });

  const _Today = () => {
    setLabel((prevState) => ({
      ...prevState,
      c: 'Store Income Last Today',
      o: 'Store Orders Last Today',
    }));
    dispatch(fetchDashboardStats(1, store_id));
  };

  const _7days = () => {
    setLabel((prevState) => ({
      ...prevState,
      c: 'Store Income Last 7days',
      o: 'Store Orders Last 7days',
    }));
    dispatch(fetchDashboardStats(7, store_id));
  };

  const _14days = () => {
    setLabel((prevState) => ({
      ...prevState,
      c: 'Store Income Last 14days',
      o: 'Store Orders Last 14days',
    }));
    dispatch(fetchDashboardStats(14, store_id));
  };

  const _30days = () => {
    setLabel((prevState) => ({
      ...prevState,
      c: 'Store Income Last 30days',
      o: 'Store Orders Last 30days',
    }));
    dispatch(fetchDashboardStats(30, store_id));
  };

  const options = [
    {
      name: 'Today',
      onClick: _Today,
    },
    {
      name: '7 days',
      onClick: _7days,
    },
    {
      name: '14 days',
      onClick: _14days,
    },
    {
      name: '30 days',
      onClick: _30days,
    },
  ];

  React.useEffect(() => {
    if (store_id) {
      dispatch(fetchDashboardStats(30, store_id));
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [store_id]);

  if (statsObj.loading || !isLoaded(store_id)) {
    return (
      <div className={classes.summaryCards}>
        <Loader />
      </div>
    );
  }

  if (isEmpty(store_id)) {
    return (
      <div className={classes.summaryCards}>
        <SummaryCard title="No store loaded" />
      </div>
    );
  }

  return (
    <div className={classes.summaryCards}>
      <>
        <SummaryCard
          title={label.c}
          titleWithAction={<VerticonOptions options={options} />}
          value={formatCash(statsObj.totalSales) ?? 0}
        />
        <SummaryCard title={label.o} value={statsObj.totalOrders ?? 0} />
      </>
    </div>
  );
}

export default ReportsSummaryStats;

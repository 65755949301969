import {
  ADD_TO_BASKET,
  CLEAR_BASKET,
  CLEAR_ERRORS,
  LOADING_UI,
  REMOVE_FROM_BASKET,
  SET_CART_ITEMS_DATA,
  SET_ERRORS,
  SET_SUCCESS,
} from '../types';

import axios from 'axios';

export const addToBasket = (order) => (dispatch) => {
  dispatch({
    type: ADD_TO_BASKET,
    payload: order,
  });
  dispatch({
    type: SET_SUCCESS,
    payload: `package ${order.order_id} added to basket`,
  });
  dispatch({
    type: CLEAR_ERRORS,
  });
};

export const dispatchOrder = (order, rider_id, fcmToken, handleClose) => (dispatch) => {
  dispatch({ type: LOADING_UI });

  const orderData = {
    order,
    rider_id,
    status: 'assigned',
    fcmToken,
  };

  axios
    .post('/dispatch', orderData)
    .then((res) => {
      handleClose(); // close assign modal
      dispatch({
        type: SET_SUCCESS,
        payload: ` ${order.cartID} assigned to rider successfully`,
      });

      // dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const dispatchOrders = (orderMap, rider_id, data, fcmToken, handleClose) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  const { updates, keys } = loopOrdersAddRiderId(orderMap, rider_id, data);

  const dataUpdated = { updates, keys, rider_id, fcmToken };

  axios
    .post('/dispatch/many', dataUpdated)
    .then((res) => {
      handleClose();
      dispatch({
        type: SET_SUCCESS,
        payload: `orders assigned to rider successfully`,
      });
      dispatch({
        type: CLEAR_BASKET,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const clearBasket = () => (dispatch) => {
  dispatch({
    type: CLEAR_BASKET,
  });
  dispatch({
    type: SET_SUCCESS,
    payload: `basket cleared`,
  });
};

export const removeFromBasket = (id) => (dispatch) => {
  dispatch({
    type: REMOVE_FROM_BASKET,
    payload: id,
  });
};

export const isDuplicate = (orderMap, order_id, order) => (dispatch) => {
  const isDup = orderMap[order_id] ? true : false;

  isDup
    ? dispatch({
        type: SET_ERRORS,
        payload: {
          message: 'package already exists in basket',
        },
      })
    : dispatch(addToBasket(order));
};

// helpers
export const isDuplicate2 = (orderMap, order_id) => {
  const isDup = orderMap[order_id] ? true : false;

  return isDup;
};

const loopOrdersAddRiderId = (orderMap, rider_id, data) => {
  // loop orders
  // add order_status, rider_id

  /**
   * // create batch update object to  delete pending orders
   *  {
   * "/path/to/node/one": null,
   *  "/path/to/node/two": null,
   *  "/path/to/node/three": null
   * }
   */
  const updates = {};
  const keys = {};

  Object.entries(orderMap).forEach(([key, value]) => {
    updates[key] = {
      ...value,
      ...data,
      rider_id,
      order_status: 'assigned',
    };
    keys[`/AdminDeliveries/pending_deliveries/${key}`] = null;
  });

  return {
    updates,
    keys,
  };
};

export const loadCartsParallel =
  (orders) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({ type: LOADING_UI });
    const db = getFirebase();
    const response = [];
    const payload = orders.filter((c) => c.cartID !== undefined);

    console.log(
      `%c${JSON.stringify(payload, null, 2)}`,
      'color: green; background: yellow; font-size: 14px',
      'paylooooooad',
    );

    Promise.all(payload.map(({ cartID }) => db.ref('/shoppingCarts').child(cartID).once('value')))
      .then((snapShot) => {
        snapShot.forEach((childSnapshot) => {
          console.log(childSnapshot.numChildren(), 'childreeen', childSnapshot.key);

          var childData = childSnapshot.val();

          for (const key in childData) {
            response.push(childData[key]);
          }
        });

        // console.log(JSON.stringify(response, null, 2), 'respooooonse');

        dispatch({
          type: SET_CART_ITEMS_DATA,
          payload: response,
        });

        dispatch({
          type: SET_SUCCESS,
          payload: 'cart items fetched successfully',
        });
      })
      .catch((error) => {
        console.log(`Carts fetch parallel  Error`);
        dispatch({
          type: SET_ERRORS,
          payload: error,
        });
      });
  };

import React, { useState } from 'react';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import Toolbar from '@material-ui/core/Toolbar';
import MaterialTable from '../../../../Base/Table/MaterialTable';
import Loader from '../../../../Base/Loader';
import SummaryCard from '../../../../Base/SummaryCard';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRecentOrdersPerDayStats } from '../../../../../Redux/Actions/reportActions';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Controls from '../../../../Controls';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },

  grow: {
    flexGrow: 1,
    flexShrink: 0.6,
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },

  summaryCard: {
    // margin: theme.spacing(1),
    flexGrow: 1,
    // padding: theme.spacing(1),
  },
}));

const columns = [
  {
    field: 'id',
    headerName: 'ID',
    title: 'ID',
    width: 78,
    filtering: false,
    emptyValue: () => <em>null</em>,
  },
  {
    field: 'client_name',
    headerName: 'Customer',
    title: 'Customer',
    width: 90,
    filtering: false,
    emptyValue: () => <em>null</em>,
  },

  {
    field: 'client_phone',
    headerName: 'Client Phone',
    title: 'Client Phone',
    width: 100,
    filtering: false,
    emptyValue: () => <em>no contact </em>,
  },
  {
    field: 'order_date',
    headerName: 'Order Date',
    title: 'Order Date',
    width: 78,
    filtering: false,
    emptyValue: () => <em>null</em>,
  },
  {
    field: 'commission',
    headerName: 'Commission',
    title: 'Commission',
    width: 150,
    filterPlaceholder: 'Filter by commission',
    emptyValue: () => <em>null</em>,
  },
  {
    field: 'delivery_fee',
    headerName: 'Delivery Fee',
    width: 150,
    title: 'Delivery Fee',
    filterPlaceholder: 'Filter by delivery fee',
    emptyValue: () => <em>null</em>,
  },
  {
    field: 'delivery_type',
    headerName: 'Delivery Type',
    title: 'Delivery Type',
    width: 150,
    filterPlaceholder: 'Filter by delivery type',
    emptyValue: () => <em>null</em>,
  },
  {
    field: 'order_type',
    headerName: 'Order Type',
    title: 'Order Type',
    width: 150,
    filterPlaceholder: 'Filter by order type',
    emptyValue: () => <em>null</em>,
  },
  {
    field: 'delivery_name',
    headerName: 'Delivery Location',
    title: 'Delivery Location',
    width: 150,
    filterPlaceholder: 'Filter by delivery location',
    emptyValue: () => <em>null</em>,
  },
  {
    field: 'fee_paid_by',
    headerName: 'Fee Paid By',
    title: 'Fee Paid By',
    width: 150,
    filtering: false,
    emptyValue: () => <em>null</em>,
  },
  //   {
  //     field: 'is_active',
  //     headerName: 'Account Status',
  //     width: 78,
  //     title: 'Account Status',
  //     render: (rowData) => (rowData.is_active ? `Active` : `Suspended`),
  //     filterPlaceholder: 'Account Status',
  //     emptyValue: () => <em>null</em>,
  //   },
];

function createData(
  id,
  orderID,
  cartID,
  order_date,
  order_status,
  order_time,
  commission,
  date,
  delivery_fee,
  delivery_long,
  delivery_lat,
  delivery_name,
  delivery_type,
  fee_paid_by,
  branch_id,
  client_id,
  client_name,
  client_phone,
  rider_id,
  sender_id,

  order_type,
) {
  return {
    id,
    orderID,
    cartID,
    order_date,
    order_status,
    order_time,
    commission,
    date,
    delivery_fee,
    delivery_long,
    delivery_lat,
    delivery_name,
    delivery_type,
    fee_paid_by,
    branch_id,
    client_id,
    client_name,
    client_phone,
    rider_id,
    sender_id,

    order_type,
  };
}

function createRows(rows) {
  const arr = [];
  rows.forEach(
    ({
      id,
      orderID,
      cartID,
      order_date,
      order_status,
      order_time,
      commission,
      date,
      delivery_fee,
      delivery_long,
      delivery_lat,
      delivery_name = null,
      fee_paid_by,
      branch_id,
      client_id,
      client_name,
      client_phone,
      delivery_type,
      rider_id,
      sender_id,

      order_type,
    }) => {
      arr.push(
        createData(
          id,
          orderID,
          cartID,
          order_date,
          order_status,
          order_time,
          commission,
          date,
          delivery_fee,
          delivery_long,
          delivery_lat,
          delivery_name,
          delivery_type,
          fee_paid_by,
          branch_id,
          client_id,
          client_name,
          client_phone,
          rider_id,
          sender_id,

          order_type,
        ),
      );
    },
  );

  return arr;
}

function RecentOrdersToday({ openInPopup }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [label, setLabel] = React.useState('Recent Orders');

  const store = useSelector((state) => state.report.store);
  const ordersObj = useSelector((state) => state.report.recentOrdersStats);

  const fetchOrders = React.useCallback((e) => {
    dispatch(fetchRecentOrdersPerDayStats(30, e.target.value));
  }, []);

  const data = !isEmpty(ordersObj) && ordersObj.orders;
  const options =
    !isEmpty(store) &&
    store.branches.map((branch) => ({
      name: branch.branch_name,
      value: branch.branch_name,
      id: branch.branch_id,
    }));

  if (!isLoaded(store)) {
    return (
      <div className={classes.summaryCards}>
        <Loader />
      </div>
    );
  }

  if (isEmpty(options)) {
    return <SummaryCard title="No data to render" />;
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar>
          <div edge="start" className={classes.grow} />
          <div className={classes.deleteButton}>
            <Controls.Select options={options} label={'Select a branch'} name={'branch'} onChange={fetchOrders} />
          </div>
        </Toolbar>

        <div>
          <MaterialTable
            title="Recent Orders Per Branch"
            columns={columns}
            actions={[
              {
                icon: 'visibility',
                tooltip: 'View Cart',
                onClick: (event, rowData) => openInPopup(rowData),
              },
            ]}
            data={isEmpty(ordersObj.orders) ? [] : createRows(ordersObj.orders)}
            options={{
              exportFileName: 'Recent Orders',
              actionsColumnIndex: -1,
            }}
          />
        </div>
      </Paper>
    </div>
  );
}

export default React.memo(RecentOrdersToday);

import React from "react";
import OrderMap from "./map/OrderMap";

function OrderContainer(props) {
    return (
        <div
            style={{
                marginTop: "40px",
            }}
        >
            <OrderMap {...props} />
            {/* <OrderTables /> */}
        </div>
    );
}

export default OrderContainer;

import { CLEAR_ERRORS, LOADING_UI, SET_ERRORS, SET_SUCCESS } from '../types';

import { db } from '../../Utils/fb.config';

// create store
export const createNewBranch =
  ({ branch, closeModal }) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({ type: LOADING_UI });

    const auth = getState().firebase.auth;

    const storeRef = db.ref(`/stores/${auth.uid}`);
    const branchRef = storeRef.child('branches').push();

    const p1 = branchRef.set({
      ...branch,
    });

    const p2 = db.ref(`/superMarketBranches/${branchRef.key}`).set({
      ...branch,
    });

    Promise.all([p1, p2])
      .then((values) => {
        closeModal();
        dispatch({
          type: SET_SUCCESS,
          payload: 'branch created successfully',
        });
        dispatch({ type: CLEAR_ERRORS });
        // history.push("/dashboard");
      })
      .catch((error) => {
        console.log(`Store Create Error`);
        dispatch({
          type: SET_ERRORS,
          payload: error,
        });
      });
  };

export const updateBranch =
  ({ branch, closeModal }) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({ type: LOADING_UI });

    const auth = getState().firebase.auth;

    const storeRef = db.ref(`/stores/${auth.uid}`);
    const branchRef = db.ref(`/superMarketBranches/${branch.branch_id}`);

    const p1 = branchRef.update({
      ...branch,
    });

    const p2 = storeRef.child(`branches/${branch.branch_id}`).update({
      ...branch,
    });

    Promise.all([p1, p2])
      .then((values) => {
        closeModal();
        dispatch({
          type: SET_SUCCESS,
          payload: 'branch updated successfully',
        });
        dispatch({ type: CLEAR_ERRORS });
        // history.push("/dashboard");
      })
      .catch((error) => {
        console.log(`Branches Update Error`);
        dispatch({
          type: SET_ERRORS,
          payload: error,
        });
      });
  };

import React, { useState } from 'react';
import { firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase';

import Button from '@material-ui/core/Button';
// mui
import Checkbox from '@material-ui/core/Checkbox';
import { DeletePopUpDialog as ConfirmDialog } from '../../../../../Base/DeleteDialog';
import Loader from '../../../../../Base/Loader';
import PropTypes from 'prop-types';
// component
import SummaryCard from '../../../../../Base/SummaryCard';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import ViewOrder from './ViewOrder';
import { compose } from 'redux';
// redux
import { connect } from 'react-redux';
import { get } from 'lodash';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTable from '../../../../../Base/Table/useTable';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },

  grow: {
    flexGrow: 0.6,
    flexShrink: 0.6,
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
}));

const headCells = [
  {
    id: 'client_name',
    numeric: false,
    disablePadding: false,
    label: 'Client Name',
  },
  {
    id: 'client_phone',
    numeric: false,
    disablePadding: true,
    label: 'Client Phone',
  },

  {
    id: 'date',
    numeric: false,
    disablePadding: true,
    label: 'Date',
  },
  {
    id: 'time',
    numeric: false,
    disablePadding: true,
    label: 'Time',
  },
  {
    id: 'dispatch',
    numeric: false,
    disablePadding: true,
    label: 'Dispatch',
  },
  {
    id: 'Actions',
    numeric: false,
    disablePadding: true,
    label: 'Actions',
  },
];

function ProductsTable(props) {
  const classes = useStyles();

  const { products, store_id, openInPopup, onDelete, confirmDialog, setConfirmDialog } = props;

  /* eslint-disable no-unused-vars */
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const { selected, setSelected, selectTableRow, TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(products, products, headCells, filterFn);

  const [snackOpen, setSnackOpen] = React.useState(false);

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const snackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpen(false);
  };

  let [orderDispatch, setOrderDispatch] = useState({});

  console.log(orderDispatch);
  if (!isLoaded(products)) {
    return <Loader />;
  }

  if (isEmpty(products)) {
    return (
      <SummaryCard
        style={{
          marginTop: '40px',
        }}
        title="No orders for branch found"
      />
    );
  }

  return (
    <>
      <div className={classes.root}>
        <Toolbar>
          <div edge="start" className={classes.grow} /> <div edge="start" className={classes.grow} />
        </Toolbar>
        <SummaryCard
          title={'Orders'}
          value={
            <>
              <TblContainer>
                <TblHead />

                <TableBody>
                  {recordsAfterPagingAndSorting().map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        // onClick={(e) => {
                        //     if (
                        //         e.target.type ===
                        //             "checkbox" ||
                        //         e.target.className.indexOf(
                        //             "Checkbox"
                        //         ) > 0
                        //     ) {
                        //         return;
                        //     }
                        // }}
                        key={`product-${row.id}`}
                        selected={isItemSelected}
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        <TableCell
                          padding="checkbox"
                          onClick={(e) => {
                            selectTableRow(row.id);
                          }}
                        >
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                            onChange={(e) => {
                              selectTableRow(row.id);
                            }}
                          />
                        </TableCell>

                        <TableCell align="left">{row.value.client_name}</TableCell>
                        <TableCell scope="row" padding="none">
                          {row.value.client_phone}
                        </TableCell>
                        <TableCell>{row.value.date}</TableCell>
                        <TableCell>{row.value.time}</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={(e) => {
                              setTimeout(function () {
                                setOrderDispatch((state) => ({
                                  ...state,
                                  [row.key]: true,
                                }));
                              }, 2000); //wait 2 seconds
                            }}
                          >
                            {orderDispatch[row.key] == true ? 'Order Dispatched' : 'Dispatch Order'}
                          </Button>
                        </TableCell>

                        <TableCell>
                          <ViewOrder order={row} cartID={row.value.cartID} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </TblContainer>
              <TblPagination />
            </>
          }
        />

        <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
      </div>
    </>
  );
}

const mapStateToProps = (state, props) => {
  return {
    products: get(state.firebase.ordered.adminOrders, `${state.firebase.auth.uid}.${props.match.params.job_id}`),
  };
};

const mapActionsToProps = {};

ProductsTable.propTypes = {
  ui: PropTypes.object.isRequired,
  openInPopup: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  store_id: PropTypes.string.isRequired,
};

const enhance = compose(
  connect(mapStateToProps, mapActionsToProps),
  firebaseConnect((props) => {
    return [`/adminOrders/${props.store_id}/${props.match.params.job_id}`];
  }),
);

export default enhance(ProductsTable);

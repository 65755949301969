import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import EditIcon from "@material-ui/icons/Edit";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import formatDate from "../../../../Utils/formatDate";
import CardActionArea from "@material-ui/core/CardActionArea";
import BankIcon from "@material-ui/icons/AccountBalance";
import ContactSupport from "@material-ui/icons/ContactSupport";
import SpeedIcon from "@material-ui/icons/Speed";
import { blue, green, pink } from "@material-ui/core/colors";
import { IconButton } from "@material-ui/core";
import { formatRelative } from "date-fns";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },

    depositContext: {
        flex: 1,
    },
    pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
    },
    blue: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
    },
    green: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: green[500],
    },
}));

function OwnerCards(props) {
    const classes = useStyles();
    const {
        setEditUser,
        user: {
            merchant_name,
            merchant_type,
            kra_pin,
            email,
            phone_number,
            isVerified,
            createdAt,
            user_Id,
            user_type,
        },
    } = props;

    return (
        <>
            <>
                <CardActionArea>
                    <CardHeader
                        onClick={() => setEditUser(true)}
                        action={
                            <IconButton aria-label="settings">
                                <EditIcon />
                            </IconButton>
                        }
                        title={`${merchant_name}`}
                        subheader={user_type}
                    />

                    <CardContent>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                        >
                            KRA pin: {kra_pin}
                        </Typography>
                    </CardContent>
                </CardActionArea>

                <CardActionArea>
                    <CardHeader
                        avatar={
                            <Avatar
                                aria-label="recipe"
                                className={classes.green}
                            >
                                <SpeedIcon />
                            </Avatar>
                        }
                        title={"Email"}
                        subheader={email}
                    />
                </CardActionArea>
                <CardActionArea>
                    <CardHeader
                        avatar={
                            <Avatar
                                aria-label="recipe"
                                className={classes.blue}
                            >
                                <BankIcon />
                            </Avatar>
                        }
                        title="PhoneNumber"
                        subheader={phone_number}
                    />
                </CardActionArea>
                <CardActionArea>
                    <CardHeader
                        avatar={
                            <Avatar
                                aria-label="recipe"
                                className={classes.green}
                            >
                                <SpeedIcon />
                            </Avatar>
                        }
                        title={"Verification Status"}
                        subheader={isVerified ? "NotVerified" : "Verified"}
                    />
                </CardActionArea>
                <CardActionArea>
                    <CardHeader
                        avatar={
                            <Avatar
                                aria-label="recipe"
                                className={classes.pink}
                            >
                                <ContactSupport />
                            </Avatar>
                        }
                        title="Joined"
                        subheader={`${formatDate(createdAt)} - ${formatRelative(
                            new Date(createdAt),
                            new Date()
                        )}`}
                    />
                </CardActionArea>
            </>
        </>
    );
}

export default OwnerCards;

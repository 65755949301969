import { deleteProduct, editProduct } from '../../../../../Redux/Actions/productActions';

import BranchProductEdit from './BranchProductEdit';
import { DeletePopUpDialog } from '../../../../Base/DeleteDialog';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import React from 'react';
import { SingleFieldModal2 } from '../../../../Base/SingleFIeldModal';
import VerticonOptions from '../../../../Base/VerticonOptions';
import { connect } from 'react-redux';

export function ProductActionButtons(props) {
  const { editProduct, deleteProduct, product } = props;

  const [openRename, setOpenRename] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);

  const handleEditProductOpen = (handleVerticonClose) => {
    setOpenRename(true);
    handleVerticonClose();
  };

  const handleDeleteDailogOpen = (handleVerticonClose) => {
    setOpenDelete(true);
    handleVerticonClose(); // closes verticon passed up from verticon options
  };

  const handleClose = () => {
    setOpenDelete(false);
    setOpenRename(false);
  };

  const handleProductEdit = (values, action) => {
    const updatedProduct = {
      ...values,
    };
    editProduct({
      updatedProduct,
      branch_id: product.value.branch_id,
      closeModal: handleClose,
      product_id: product.key,
    });

    action.resetForm();
    setOpenRename(false);
  };

  const handleProductDelete = () => {
    deleteProduct({
      id: product.key,
      branch_id: product.value.branch_id,
    });
  };

  // verticon options
  const options = [
    { name: 'edit product', onClick: handleEditProductOpen },
    { name: 'delete product', onClick: handleDeleteDailogOpen },
  ];

  return (
    <>
      <ListItem button disableRipple>
        <ListItemIcon>
          <VerticonOptions options={options} />
        </ListItemIcon>
      </ListItem>

      <SingleFieldModal2 title="Edit Product" handleClose={handleClose} open={openRename}>
        <BranchProductEdit product={product} editProduct={handleProductEdit} />
      </SingleFieldModal2>

      <DeletePopUpDialog
        title={`delete ${product.value.product_name}`}
        onSave={handleProductDelete}
        message="This action cannot be undone, are you sure you want to proceed?"
        open={openDelete}
        handleClose={handleClose}
      />
    </>
  );
}

const mapStateToProps = (state) => ({});

const mapActionsToProps = {
  editProduct,
  deleteProduct,
};

export default connect(mapStateToProps, mapActionsToProps)(ProductActionButtons);

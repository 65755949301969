import {
  CLEAR_CURRENT_ORDER,
  LOADING_UI,
  SET_CURRENT_ORDER,
  SET_ERRORS,
  SET_SUCCESS,
  SHOW_ALL_UNASSIGNED,
  TOGGLE_ORDER_DETAILS,
} from '../types';

import Geocode from 'react-geocode';
import { db } from '../../Utils/fb.config';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);

// set response language. Defaults to english.
Geocode.setLanguage('en');

Geocode.setLocationType('ROOFTOP');

// Enable or disable logs. Its optional.
Geocode.enableDebug();
export const setStoreLocation =
  (location, address, branch_id, store_id) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({ type: LOADING_UI });

    const branchRef = db.ref(`/superMarketBranches/${branch_id}`).update({
      ...location,
      branch_location: address,
    });

    const storeRef = db.ref(`/stores/${store_id}/branches/${branch_id}`).update({
      ...location,
      branch_location: address,
    });

    Promise.all([branchRef, storeRef])
      .then((value) => {
        dispatch({
          type: SET_SUCCESS,
          payload: `branch location updated to ${address} successfully`,
        });

        // history.push("/dashboard");
      })
      .catch((error) => {
        console.log(`branch Update Error`);
        dispatch({
          type: SET_ERRORS,
          payload: error,
        });
      });
  };

export const geoCodeToAddress = async ({ lat, lng }) => {
  // Get address from latitude & longitude.
  return Geocode.fromLatLng(lat, lng).then(
    (response) => {
      const address = response.results[0].formatted_address;
      console.log(response.results);
      return address;
    },
    (error) => {
      throw error;
    },
  );
};

export const toggleOrderDetails =
  ({ order, toggle }) =>
  (dispatch) => {
    dispatch({
      type: SET_CURRENT_ORDER,
      payload: order,
    });
    dispatch({
      type: TOGGLE_ORDER_DETAILS,
      payload: toggle,
    });
  };

export const closeOrderDetails = () => (dispatch) => {
  dispatch({ type: CLEAR_CURRENT_ORDER });
};

export const toggleShowAllUnassigned = (showAll) => (dispatch) => {
  dispatch({ type: SHOW_ALL_UNASSIGNED, payload: showAll });
};

export const unAssignedPanToOrder = (order) => (dispatch) => dispatch({ payload: order, type: 'UN_ASSIGNED_SELECTED' });

import { isEmpty, isLoaded, useFirebaseConnect } from 'react-redux-firebase';
import {
  toggleShowAllUnassigned,
  toggleOrderDetails,
  unAssignedPanToOrder,
} from '../../../../../../../../../Redux/Actions/mapActions';

import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../../../../../../../Base/Loader';
import OrderList from './OrderList';
import React from 'react';
import { get } from 'lodash';

const filterUnassigned = (orders) => {
  if (isEmpty(orders)) return null;

  return orders.filter(({ key, value: { delivery_type = undefined } }, index) => {
    return delivery_type !== 'Pick Up';
  });
};

function UnAssignedList(props) {
  const dispatch = useDispatch();

  const store_id = useSelector((state) => state.firebase.auth.uid);
  const branch_id = useSelector((state) => state.constant.constant.job_id);
  useFirebaseConnect([
    {
      path: `/adminOrders/${store_id}/${branch_id}`,
    },
  ]);

  const orders = useSelector((state) => {
    let orders = get(state.firebase.ordered.adminOrders, `${state.firebase.auth.uid}.${branch_id}`);
    return orders;
  });

  if (!isLoaded(orders)) {
    return <Loader />;
  }

  const handleRenderAll = (showAll) => {
    dispatch(toggleShowAllUnassigned(showAll));
  };

  const handlePanToOrder = (order) => {
    dispatch(unAssignedPanToOrder(order));
  };

  const handleToggleDetailsView = ({ order, toggle }) => {
    dispatch(toggleOrderDetails({ order, toggle }));
  };

  return (
    <OrderList
      orders={filterUnassigned(orders)}
      handlePanToOrder={handlePanToOrder}
      listType="unassigned"
      handleRenderAll={handleRenderAll}
      handleToggleDetailsView={handleToggleDetailsView}
    />
  );
}

export default UnAssignedList;

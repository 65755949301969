import getDashboardRoutes, { getSidebarRoutes } from '../../../Utils/helpers';

import AppBarAndDrawer from '../../Container/AppBar';
import Main from './Main';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { auth as fbAuth } from '../../../Utils/fb.config';
import { logoutUser } from '../../../Redux/Actions/authActions';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
}));

function Dashboard(props) {
  const classes = useStyles();
  /* eslint-disable no-unused-vars */

  const dispatch = useDispatch();
  const {
    user,
    match: { path },
    auth,
    logoutUser,
  } = props;

  React.useEffect(() => {
    fbAuth.onAuthStateChanged((user) => {
      if (!user) {
        dispatch(logoutUser());
        return false;
      }
    });
  }, []);

  return (
    <div className={classes.root}>
      <>
        <AppBarAndDrawer routes={getSidebarRoutes(user.merchant_type)} matchPath={path} user={user} />
        <Main routes={getDashboardRoutes(user.merchant_type)} matchPath={path} authenticated={auth.uid} user={user} />
      </>
    </div>
  );
}

Dashboard.propTypes = {
  user: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
  authenticated: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  authenticated: state.firebase.auth.uid,
  auth: state.firebase.auth,
});

const mapActionsToProps = {
  logoutUser,
};

export default connect(mapStateToProps, mapActionsToProps)(Dashboard);

import { Field } from "formik";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { TextField as MuiTextField } from "formik-material-ui";
import React from "react";
import { TextField } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

function Input(props) {
    const { name, value, onChange, label, ...rest } = props;
    return (
        <TextField
            variant="outlined"
            name={name}
            value={value}
            onChange={onChange}
            label={label}
            {...rest}
        />
    );
}


export function Input2(props) {
    const { value, label, name, ...rest } = props;

    const [values, setValues] = React.useState({
        showPassword: false,
    });

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <MuiTextField
            name={name}
            type={values.showPassword ? "text" : "password"}
            value={value}
            label={label}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {values.showPassword ? (
                                <Visibility />
                            ) : (
                                <VisibilityOff />
                            )}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            component={TextField}
            {...rest}
        />
    );
}




export default Input;

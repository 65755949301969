import { CLEAR_ERRORS, LOADING_UI, SET_ERRORS, SET_SUCCESS } from '../types';

import { db } from '../../Utils/fb.config';

// create store
export const createNewBranch =
  ({ branch, closeModal }) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({ type: LOADING_UI });

    const auth = getState().firebase.auth;

    const branchRef = db.ref(`/gasStores/${auth.uid}`).child('branches').push();
    // Get the unique key generated by push()
    const branch_id = branchRef.key;

    branchRef
      .set({
        ...branch,
      })
      .then((snap) => {
        return db.ref(`/branches/${branch_id}`).set({
          ...branch,
          branch_id,
        });
      })
      .then(() => {
        closeModal();
        dispatch({
          type: SET_SUCCESS,
          payload: 'branch created successfully',
        });
        dispatch({ type: CLEAR_ERRORS });
      })
      .catch((error) => {
        console.log(`Store Create Error`);
        dispatch({
          type: SET_ERRORS,
          payload: error,
        });
      });
  };

export const setStoreLocation =
  (location, address, branch_id, store_id) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({ type: LOADING_UI });

    console.log({
      location,
      address,
      branch_id,
      store_id,
    });

    const up1 = db.ref(`/gasStores/${store_id}/branches/${branch_id}`).update({
      ...location,
      branch_location: address,
    });

    const up2 = db.ref(`/branches/${branch_id}`).update({
      ...location,
      branch_location: address,
    });

    const updates = [up1, up2];

    Promise.allSettled(updates)
      .then((value) => {
        dispatch({
          type: SET_SUCCESS,
          payload: `branch location updated to ${address} successfully`,
        });
      })
      .catch((error) => {
        console.log(`branch Update Error`);
        dispatch({
          type: SET_ERRORS,
          payload: error,
        });
      });
  };

// updateStore store
export const updateStore =
  ({ store_id, store_data }) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({ type: LOADING_UI });

    db.ref(`/gasStores/${store_id}`)
      .update({
        ...store_data,
      })
      .then((value) => {
        dispatch({
          type: SET_SUCCESS,
          payload: `store updated successfully`,
        });
        dispatch({ type: CLEAR_ERRORS });
        // history.push("/dashboard");
      })
      .catch((error) => {
        console.log(`Store Update Error`);
        dispatch({
          type: SET_ERRORS,
          payload: error,
        });
      });
  };

import React, { useState } from "react";
import { isEmpty, isLoaded, useFirebaseConnect } from "react-redux-firebase";

import Content from "../../../../Container/Content";
import ProductsTable from "./ProductsTable";

function GasOrders(props) {
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
    });

    const [openPopup, setOpenPopup] = useState(false);
    const [record, setRecord] = useState(null);

    const onDelete = (ids) => {
        // deleted selected drivers
        // handleDelete(ids, job_id);
        console.log(ids);
    };

    // modal for driver data
    const openInPopup = (item) => {
        setRecord(item);
        setOpenPopup(true);
    };

    console.log(props);

    return (
        <>
            <ProductsTable
                openInPopup={openInPopup}
                onDelete={onDelete}
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
                {...props}
            />
            {/* {record && (
                <ProductsProfile
                    record={record}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                    setRecord={setRecord}
                />
            )} */}
        </>
    );
}

export default GasOrders;

import BranchInfo from "./BranchInfo";
import BranchItemsInfo from "./BranchItemsInfo";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    fixedHeight: {
        height: 300,
    },
    balanceCard: {
        height: 200,
    },
}));

function BranchHeader(props) {
    const classes = useStyles();
    const balancePaper = clsx(classes.paper, classes.balanceCard);
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={7} lg={8}>
                <Paper className={balancePaper}>
                    <BranchInfo {...props} />
                </Paper>
            </Grid>
            {/* Recent TotalCard */}
            <Grid item xs={12} md={5} lg={4}>
                <Paper className={balancePaper}>
                    <BranchItemsInfo />
                </Paper>
            </Grid>
        </Grid>
    );
}

BranchHeader.propTypes = {
    branch: PropTypes.object.isRequired,
};

export default BranchHeader;

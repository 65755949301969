import * as Yup from 'yup';

import { Field, Form, Formik } from 'formik';

import AppBar from './AppBar';
//mui stuff
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Controls from '../../../Controls/index';
// components
import Copyright from '../../../Base/Copyright';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import { TextField } from 'formik-material-ui';
import Typography from '@material-ui/core/Typography';
// redux
import { connect } from 'react-redux';
import { createStyles } from '@material-ui/core/styles';
import { loginUser } from '../../../../Redux/Actions/authActions';
import withStyles from '@material-ui/core/styles/withStyles';

const loginSchema = Yup.object({
  email: Yup.string().required('email is required'),
  password: Yup.string().required('password is required'),
});

const styles = createStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    paddingTop: '35px',
  },
  paper: {
    margin: theme.spacing(8, 8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // color: theme.palette.text.secondary,
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  progress: {
    position: 'absolute',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export class Login extends React.Component {
  // making sure user does not return here if authenticated
  componentDidMount() {
    if (this.props.auth.authenticated) {
      this.props.history.push('/dashboard');
    }
  }

  handleSubmit = (values, action) => {
    const userData = {
      ...values,
    };

    this.props.loginUser(userData, this.props.history);
    action.resetForm();
  };

  render() {
    const {
      classes,
      ui: { loading },
    } = this.props;

    return (
      <Grid container component="main" className={classes.root}>
        <AppBar />
        <CssBaseline />
        <Grid container justify="center" className={classes.image}>
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            // direction="row"
            elevation={6}
            square
          >
            <Grid className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Login
              </Typography>
              <div className={classes.form} noValidate onSubmit={this.handleSubmit}>
                <Formik
                  initialValues={{
                    email: '',
                    password: '',
                  }}
                  validationSchema={loginSchema}
                  onSubmit={this.handleSubmit}
                >
                  <Form>
                    <Field
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      component={TextField}
                    />

                    <Controls.Password
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                    />

                    <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={loading}
                      className={classes.submit}
                      type="submit"
                    >
                      Sign In
                      {loading && <CircularProgress size={30} className={classes.progress} />}
                    </Button>
                  </Form>
                </Formik>

                <Grid container>
                  <Grid item xs>
                    <Link to="/forgot-password" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link to="/register" variant="body2">
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
                <Box mt={5}>
                  <Copyright />
                </Box>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  ui: state.ui,
});

const mapActionsToProps = {
  loginUser,
};

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(Login));

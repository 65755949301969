import BranchDetails from './Components/Pages/Company/Store/BranchDetails';
import CompanyReg from './Components/Pages/Auth/Register/SImpleCompanyReg/CompanyReg';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import DriverRegister from './Components/Pages/Auth/Register/SImpleCompanyReg/DriverRegister';
import Gas from './Components/Pages/Company/Gas/Gas';
import GasContainer from './Components/Pages/Company/Gas/GasContainer';
import GasDetails from './Components/Pages/Company/Gas/GasDetails';
import GasProducts from './Components/Pages/Company/Gas/Products/Products';
import GasProfile from './Components/Pages/Company/Gas/Profile/Profile';
// customer company routes
import Jobs from './Components/Pages/Company/Store/Store';
import JobsContainer from './Components/Pages/Company/Store/StoreContainer';
import Login from './Components/Pages/Auth/Login/Login';
import Metric from './Components/Pages/Company/Metric';
import Orders from './Components/Pages/Company/Store/Products/Products';
import PersonIcon from '@material-ui/icons/Person';
import RegisterPage from './Components/Pages/Auth/Register/SImpleCompanyReg/RegisterPage';
import SportsMotorsportsOutlinedIcon from '@material-ui/icons/SportsMotorsportsOutlined';
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';
import UserProfile from './Components/Pages/Company/Profile/Profile';

export const defaultRoutes = [
  {
    path: '/register',
    exact: true,
    component: RegisterPage,
  },
  {
    path: '/store-registration',
    exact: true,
    component: CompanyReg,
  },
  {
    path: '/driver-registration',
    exact: true,
    component: DriverRegister,
  },
  {
    path: '/login',
    exact: true,
    component: Login,
  },
];

const dashboardRoutes = [
  {
    path: 'reports',
    component: Metric,
    text: 'reports',
    icon: DataUsageIcon,
    layout: '/company',
  },
  {
    path: 'stores',
    component: JobsContainer,
    text: 'stores',
    icon: StorefrontOutlinedIcon,
    layout: '/company',

    routes: [
      {
        path: '',
        exact: true,
        component: Jobs,
        text: 'stores',
      },

      {
        path: ':job_id/branch-details',
        component: BranchDetails,
      },

      {
        path: ':id/orders',
        component: Orders,
      },
    ],
  },
  {
    path: 'gas',
    component: GasContainer,
    text: 'gas',
    icon: SportsMotorsportsOutlinedIcon,
    layout: '/company',

    routes: [
      {
        path: '',
        exact: true,
        component: Gas,
        text: 'gas',
      },

      {
        path: ':job_id/branch-details',
        component: GasDetails,
      },

      {
        path: ':id/products',
        component: GasProducts,
      },
    ],
  },
  {
    path: 'profile',
    component: UserProfile,
    text: 'profile',
    icon: PersonIcon,
    layout: '/company',
  },
];

export const superMarketRoutes = [
  {
    path: 'reports',
    component: Metric,
    text: 'reports',
    icon: DataUsageIcon,
    layout: '/company',
  },
  {
    path: 'stores',
    component: JobsContainer,
    text: 'stores',
    icon: StorefrontOutlinedIcon,
    layout: '/company',

    routes: [
      {
        path: '',
        exact: true,
        component: Jobs,
        text: 'stores',
      },

      {
        path: ':job_id/branch-details',
        component: BranchDetails,
      },

      {
        path: ':id/orders',
        component: Orders,
      },
    ],
  },
  {
    path: 'profile',
    component: UserProfile,
    text: 'profile',
    icon: PersonIcon,
    layout: '/company',
  },
];

export const gasRoutes = [
  {
    path: 'reports',
    component: Metric,
    text: 'reports',
    icon: DataUsageIcon,
    layout: '/company',
  },
  {
    path: 'gas',
    component: GasContainer,
    text: 'gas',
    icon: SportsMotorsportsOutlinedIcon,
    layout: '/company',

    routes: [
      {
        path: '',
        exact: true,
        component: Gas,
        text: 'gas',
      },

      {
        path: ':job_id/branch-details',
        component: GasDetails,
      },

      {
        path: ':id/products',
        component: GasProducts,
      },
    ],
  },
  {
    path: 'vendor',
    component: GasProfile,
    text: 'vendor',
    icon: PersonIcon,
    layout: '/company',
  },
];

export default dashboardRoutes;

import {
    Combobox,
    ComboboxInput,
    ComboboxPopover,
    ComboboxList,
    ComboboxOption,
} from "@reach/combobox";
import React from "react";
import usePlacesAutoComplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useDispatch } from "react-redux";
import { errorAlert } from "../../../../../../Redux/Actions/uiActions";

const useStyles = makeStyles((theme) => ({
    search: {
        position: "absolute",
        top: "2rem",
        left: "64%",
        transform: `translateX(-50%)`,
        width: "100%",
        maxWidth: "400px",
        zIndex: 10,

        "& input": {
            padding: "0.5rem",
            fontSize: "1.2rem",
            width: "100%",
        },
    },
}));

function Search(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { center, panTo } = props;

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutoComplete({
        requestOptions: {
            location: { lat: () => center.lat, lng: () => center.lng },
            radius: 200 * 1000,
        },
    });

    return (
        <div className={classes.search}>
            <Combobox
                onSelect={async (address) => {
                    setValue(address, false); // setting search field to the current select item
                    clearSuggestions(); // clear search suggestions
                    try {
                        const results = await getGeocode({ address });
                        const { lat, lng } = await getLatLng(results[0]);

                        panTo({ lat, lng });
                    } catch (error) {
                        dispatch(errorAlert(error));
                        console.log(error);
                    }
                }}
            >
                <ComboboxInput
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value);
                    }}
                    disabled={!ready}
                    placeholder="Search an address"
                />
                <ComboboxPopover>
                    <ComboboxList>
                        {status === "OK" &&
                            data.map(({ place_id, description }) => (
                                <ComboboxOption
                                    key={place_id}
                                    value={description}
                                />
                            ))}
                    </ComboboxList>
                </ComboboxPopover>
            </Combobox>
        </div>
    );
}

export default Search;

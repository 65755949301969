import { SET_ERRORS, CLEAR_ERRORS, SET_SUCCESS } from "../types";

export const errorAlert = (payload) => (dispatch) => {
    dispatch({
        type: SET_ERRORS,
        payload,
    });

    dispatch({ type: CLEAR_ERRORS });
};

export const setSuccess = (message) => (dispatch) => {
    dispatch({
        type: SET_SUCCESS,
        payload: message,
    });
};

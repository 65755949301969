import { CLEAR_ERRORS, LOADING_UI, SET_ERRORS, SET_SUCCESS } from '../types';

import { db } from '../../Utils/fb.config';

// create store
export const createNewProduct =
  ({ product, closeModal }) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({ type: LOADING_UI });

    db.ref(`/products/${product.branch_id}`)
      .push({
        ...product,
      })
      .then((val) => {
        closeModal();
        dispatch({
          type: SET_SUCCESS,
          payload: `product created`,
        });
      })
      .catch((error) => {
        console.log(`Product Create Error`);
        dispatch({
          type: SET_ERRORS,
          payload: error,
        });
      });
  };

export const deleteProduct =
  ({ id, branch_id }) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({ type: LOADING_UI });

    db.ref(`/products/${branch_id}/${id}`)
      .remove()
      .then((value) => {
        dispatch({
          type: SET_SUCCESS,
          payload: `product deleted`,
        });
        dispatch({ type: CLEAR_ERRORS });
        // history.push("/dashboard");
      })
      .catch((error) => {
        console.log(`Product delete Error`);
        dispatch({
          type: SET_ERRORS,
          payload: error,
        });
      });
  };

export const editProduct =
  ({ updatedProduct, branch_id, closeModal, product_id }) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({ type: LOADING_UI });

    db.ref(`/products/${branch_id}/${product_id}`)
      .update({
        ...updatedProduct,
      })
      .then((value) => {
        dispatch({
          type: SET_SUCCESS,
          payload: `product updated`,
        });
        closeModal();
        dispatch({ type: CLEAR_ERRORS });
        // history.push("/dashboard");
      })
      .catch((error) => {
        console.log(`Product update Error`);
        dispatch({
          type: SET_ERRORS,
          payload: error,
        });
      });
  };

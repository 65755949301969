import "@reach/combobox/styles.css";

import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";

import BranchMarker from "./BranchMarker";
import { Grid } from "@material-ui/core";
import Loader from "../../../../../Base/Loader";
import React from "react";
import Search from "./Search";
import { errorAlert } from "../../../../../../Redux/Actions/uiActions";
import { get } from "lodash";

const style = {
    width: "100%",
    height: "85vh",
    position: "relative",
    maxHeight: "85vh",
    overflowY: "scroll",
};

const center = {
    lat: -1.292066,
    lng: 36.821945,
};

const options = {
    // styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
};

// to avoid re-renders
const libraries = ["places"];

const MapContainer = (props) => {
    const [markers, setMarkers] = React.useState(null);
    const [selected, setSelected] = React.useState(null);
    const dispatch = useDispatch();

    const branch_id = useSelector((state) => state.constant.constant.branch_id);
    const store = useSelector((state) => {
        return get(state.firebase.data.gasStores, state.firebase.auth.uid);
    });

    const branch = store.branches[branch_id];

    // prevent re-renders
    const onMarkerSet = React.useCallback(({ branch_lat, branch_lng }) => {
        setMarkers({
            branch_lat: branch_lat,
            branch_lng: branch_lng,
        });
    }, []);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });

    // prevent re-renders
    const onMapClick = React.useCallback((e) => {
        console.log(e, "eveeeeeeeeeeeeeeeeeent");
        setMarkers({
            branch_lat: e.latLng.lat(),
            branch_lng: e.latLng.lng(),
        });

        console.log(markers);
    }, []);

    // get map ref
    const mapRef = React.useRef();
    const onMapLoad = React.useCallback((map) => {
        mapRef.current = map;
    }, []);

    // move map to a specific location
    const panTo = React.useCallback(({ lat, lng }) => {
        mapRef.current.panTo({ lat: parseFloat(lat), lng: parseFloat(lng) });
        mapRef.current.setZoom(15);
    }, []);

    if (loadError) {
        return dispatch(errorAlert({ message: "Error loading maps" }));
    }

    if (!isLoaded) {
        return <Loader />;
    }

    return (
        <div
            style={{
                maxHeight: "85vh",
                position: "relative",
                marginTop: "40px",
            }}
        >
            <Search center={center} panTo={panTo} />
            <Grid container>
                <Grid item xs={12}>
                    <GoogleMap
                        mapContainerStyle={style}
                        zoom={11}
                        center={center}
                        options={options}
                        onClick={onMapClick}
                        onLoad={onMapLoad}
                    >
                        {isLoaded && (
                            <BranchMarker
                                panTo={panTo}
                                marker={markers}
                                branch={branch}
                                onMarkerSet={onMarkerSet}
                                {...props}
                            />
                        )}
                    </GoogleMap>
                </Grid>
            </Grid>
        </div>
    );
};

export default MapContainer;

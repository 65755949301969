import React from "react";
import { useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirebaseConnect } from "react-redux-firebase";
import Loader from "../../../../../../../../Base/Loader";
import OrderList from "./OrderList";
import { useDispatch } from "react-redux";
import { toggleOrderDetails } from "../../../../../../../../../Redux/Actions/mapActions";
import { get } from "lodash";

function AssignedList(props) {
    const dispatch = useDispatch();
    const store_id = useSelector((state) => state.firebase.auth.uid);
    const branch_id = useSelector((state) => state.constant.constant.job_id);

    useFirebaseConnect(
        `AdminDeliveries/assigned_deliveries/${store_id}/${branch_id}`
    );

    const orders = useSelector((state) => {
        return state.firebase.ordered.AdminDeliveries
            ? get(
                  state.firebase.ordered.AdminDeliveries.assigned_deliveries,
                  `${state.firebase.auth.uid}.${branch_id}`
              )
            : [];
    });

    if (!isLoaded(orders)) {
        return <Loader />;
    }
    if (isEmpty(orders)) {
        return null;
    }

    const handleToggleDetailsView = ({ order, toggle }) => {
        dispatch(toggleOrderDetails({ order, toggle }));
    };

    return (
        <OrderList
            orders={orders}
            handleToggleDetailsView={handleToggleDetailsView}
            listType="assigned"
        />
    );
}

export default AssignedList;

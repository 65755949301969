import React, { Component } from 'react';
import Content from './Content';
import SummaryCard from '../Base/SummaryCard';
import Controls from '../Controls';
import Typography from '@material-ui/core/Typography';

export default class ErrorBoundary extends Component {
  state = {
    errorMessage: '',
    info: '',
    error: '',
  };

  static getDerivedStateFromError(error) {
    return { errorMessage: error.toString() };
  }

  componentDidCatch(error, info) {
    // set error to state
    this.setState({ error, info });

    console.log(error, JSON.stringify(info.componentStack, null, 2));
  }

  refreshPage = () => this.history.go(0);

  goHome = () => (window.location.href = '/dashboard');

  render() {
    const { errorMessage } = this.state;
    const { render } = this.props;

    if (render) {
      // using render props pattern to optionally show custom ui
      return render;
    }
    // else default to basic ui
    if (errorMessage) {
      return (
        <div
          style={{
            height: '100vh',
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <SummaryCard
            title="Oops, Something went wrong !"
            component={
              <>
                <Typography variant="h5" component="h2" gutterBottom>
                  {this.state.error.toString()}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {JSON.stringify(this.state.info.componentStack, null, 3)}
                </Typography>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Controls.Button onClick={this.goHome} color="secondary" text={'Refresh The page'} />
                </div>
              </>
            }
          />
        </div>
      );
    }

    return this.props.children;
  }
}

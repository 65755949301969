import React, { useEffect } from 'react';
import Loader from '../../../../Base/Loader';
import get from 'lodash';
import { useSelector } from 'react-redux';
import { isLoaded, isEmpty, useFirebaseConnect } from 'react-redux-firebase';
import SummaryCard from '../../../../Base/SummaryCard';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import firebase from '../../../../../Utils/fb.config';
import { SET_ERRORS } from '../../../../../Redux/types';

const db = firebase.database();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(
  branch_id,
  cartID,
  date,
  manufacture,
  product_id,
  product_name,
  product_price,
  product_size,
  quantity,
  time,
  totalPrice,
) {
  return {
    branch_id,
    cartID,
    date,
    manufacture,
    product_id,
    product_name,
    product_price,
    product_size,
    quantity,
    time,
    totalPrice,
  };
}

const columns = [
  'date',
  'manufacture',
  'product_id',
  'product_name',
  'product_price',
  'product_size',
  'quantity',
  'time',
  'totalPrice',
];

function RecentOrderDetailPanel({ cartID, client_id }) {
  const classes = useStyles();

  const [orders, setorders] = React.useState(null);
  const [failed, setFailed] = React.useState(false);

  useEffect(() => {
    const ref = db.ref(`shoppingCarts/${client_id}/${cartID}`);

    ref.on('value', (snap) => {
      setorders(snap.val());
    });

    return () => ref.off();
  }, [cartID]);

  // if (!isLoaded(items)) {
  //   return <Loader />;
  // }

  // if (isEmpty(items)) {
  //   return (
  //     <SummaryCard
  //       style={{
  //         margin: '10px',
  //       }}
  //       title="No cart items found"
  //     />
  //   );
  // }

  return (
    <>
      {isEmpty(orders) ? (
        <p>No Cart Found</p>
      ) : (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((c) => (
                  <TableCell align={c.align}>{c}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(orders).map((key, index) => (
                <TableRow key={key}>
                  <TableCell align="right">{orders[key].date}</TableCell>{' '}
                  <TableCell>{orders[key].manufacture}</TableCell>
                  <TableCell>{orders[key].product_id}</TableCell> <TableCell>{orders[key].product_name}</TableCell>{' '}
                  <TableCell align="right">{orders[key].product_price}</TableCell>{' '}
                  <TableCell>{orders[key].product_size}</TableCell>
                  <TableCell>{orders[key].quantity}</TableCell>
                  <TableCell>{orders[key].time}</TableCell>
                  <TableCell>{orders[key].totalPrice}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

export default RecentOrderDetailPanel;

import React from "react";
import { drawerWidth } from "../../../../Container/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Route, Link, Switch } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    appToolbar: {
        marginTop: theme.spacing(3),
    },
}));

export default function SimpleTabs(props) {
    const classes = useStyles();

    const { tabRoutes, url } = props;
    console.log(url);
    return (
        <div className={classes.root}>
            <Route
                path={url}
                render={(history) => (
                    <AppBar
                        color="primary"
                        position="static"
                        className={classes.appToolbar}
                    >
                        <Tabs
                            value={history.location.pathname}
                            aria-label="simple tabs example"
                        >
                            {tabRoutes.map((route) => (
                                <Tab
                                    label={route.label}
                                    value={route.value}
                                    component={Link}
                                    to={route.value}
                                />
                            ))}
                        </Tabs>
                    </AppBar>
                )}
            />

            <Switch>
                {tabRoutes.map(({ component: Component, value }) => (
                    <Route path={value}>
                        <Component {...props} />
                    </Route>
                ))}
            </Switch>
        </div>
    );
}

import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import {
    setStoreLocation,
    geoCodeToAddress,
    isDuplicate2,
} from "../../../../../../Redux/Actions/mapActions";
import { errorAlert } from "../../../../../../Redux/Actions/uiActions";
import { useDispatch, useSelector } from "react-redux";

function SetStoreLocation(props) {
    const dispatch = useDispatch();
    const { branch, marker, id, store_id } = props;

    const handleSetStoreLocation = async (location, branch_id, store_id) => {
        try {
            const address = await geoCodeToAddress({
                lat: "" + location.branch_lat,
                lng: "" + location.branch_lng,
            });

            console.log(address, "herererer");

            dispatch(setStoreLocation(location, address, branch_id, store_id));
        } catch (error) {
            dispatch(errorAlert(error));
            console.log(error);
        }
    };

    return (
        <Tooltip title={"set or update store location"} placement="bottom">
            <Button
                color={
                    branch.branch_location !== undefined
                        ? "secondary"
                        : "primary"
                }
                variant="contained"
                size="small"
                // disabled={branch.branch_location ? "true" : "false"}
                // startIcon={<DeleteIcon />}
                onClick={(e) => handleSetStoreLocation(marker, id, store_id)}
            >
                {branch.branch_location !== undefined
                    ? "Set Location"
                    : "Update Location"}
            </Button>
        </Tooltip>
    );
}

export default SetStoreLocation;

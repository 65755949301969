import React, { useEffect } from "react";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { Marker, InfoWindow } from "@react-google-maps/api";
import Loader from "../../../../../Base/Loader";
import BranchMarkerInfoCard from "./BranchMarkerInfoCard";
import storeIcon from "../../../../../../store.svg";
import { useSelector } from "react-redux";

// <img src="https://img.icons8.com/emoji/48/000000/package-.png"/>
function BranchMarker(props) {
    const { panTo, marker, match, branch, onMarkerSet } = props;
    const stores = useSelector((state) => state.firebase.data.stores);
    const store_id = branch.store_id;
    const store = stores[store_id];

    const [selected, setSelected] = React.useState(null);
    const position =
        marker == null
            ? { lat: branch.branch_lat, lng: branch.branch_lng }
            : {
                  lat: marker.branch_lat,
                  lng: marker.branch_lng,
              };

    useEffect(() => {
        console.log(position);
        panTo({
            lat: parseFloat(position.lat),
            lng: parseFloat(position.lng),
        });
    }, [position, marker]);

    useEffect(() => {
        console.log(branch);
        panTo({
            lat: parseFloat(branch.branch_lat),
            lng: parseFloat(branch.branch_lng),
        });

        onMarkerSet({
            branch_lat: parseFloat(branch.branch_lat),
            branch_lng: parseFloat(branch.branch_lng),
        });
    }, []);

    return (
        <div>
            <>
                <Marker
                    position={position}
                    icon={{
                        url: storeIcon,
                        scaledSize: new window.google.maps.Size(30, 30),
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(15, 15),
                    }}
                    onClick={() => {
                        setSelected(marker);
                        console.log("clicked");
                    }}
                />

                {/* render info about branch */}
                {selected ? (
                    <InfoWindow
                        position={{
                            lat: selected.branch_lat,
                            lng: selected.branch_lng,
                        }}
                        onCloseClick={() => {
                            setSelected(null);
                        }}
                    >
                        <BranchMarkerInfoCard
                            marker={selected}
                            branch={branch}
                            store={store}
                            id={match.params.job_id}
                            store_id={store_id}
                        />
                    </InfoWindow>
                ) : null}
            </>
        </div>
    );
}

export default BranchMarker;

import React from 'react';
import { errorAlert } from '../Redux/Actions/uiActions';
import { useDispatch } from 'react-redux';

const useImageHandler = () => {
  const dispatch = useDispatch();
  const [file, setFile] = React.useState(null);
  const [imageUrl, setImageUrl] = React.useState(null); //once we have an image, we will duplicate it here
  const types = ['image/png', 'image/jpeg', 'image/jpg'];
  const imageChangeHandler = (e) => {
    let selected = e.target.files[0];
    if (selected && types.includes(selected.type)) {
      setFile(selected);
      console.log(selected);

      // clearError
    } else {
      setFile(null);
      // setALertError e,g select an image file (png or jpeg)
      dispatch(errorAlert('select an image file'));
    }
  };

  return {
    file,
    setFile,
    imageChangeHandler,
    imageUrl,
    setImageUrl,
  };
};

export default useImageHandler;

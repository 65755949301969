import * as Yup from 'yup';

import { SingleFieldForm, SingleFieldModal2 } from '../../../Base/SingleFIeldModal';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuOptions from '../../../Base/VerticonOptions';
import React from 'react';
import { updateBranch } from '../../../../Redux/Actions/branchActions';
import { useDispatch } from 'react-redux';

const branchSchema = Yup.object({
  name: Yup.string().required('branch name is required'),
});

function BranchCardActions({ branch }) {
  const dispatch = useDispatch();

  const [openRename, setOpenRename] = React.useState(false);

  const handleEditProductOpen = (handleVerticonClose) => {
    setOpenRename(true);
    handleVerticonClose(); // closes verticon passed up from verticon options
  };

  const handleClose = () => {
    // close menu options
    setOpenRename(false);
  };

  const handleBranchEdit = (values, action) => {
    const updatedBranch = {
      ...branch,
      branch_name: values.name,
    };
    dispatch(
      updateBranch({
        branch: updatedBranch,
        closeModal: handleClose,
      }),
    );

    action.resetForm();
    setOpenRename(false);
  };

  // verticon options
  const options = [{ name: 'edit name', onClick: handleEditProductOpen }];

  return (
    <>
      <ListItem disableRipple>
        <ListItemIcon>
          <MenuOptions options={options} />
        </ListItemIcon>
      </ListItem>

      <SingleFieldModal2 title="Edit Branch" handleClose={handleClose} open={openRename}>
        <SingleFieldForm
          label="Branch Name"
          handleSubmit={handleBranchEdit}
          validationSchema={branchSchema}
          data={branch.branch_name}
        ></SingleFieldForm>
      </SingleFieldModal2>
    </>
  );
}

export default BranchCardActions;

import { CLEAR_ERRORS, LOADING_UI, SET_ERRORS, SET_SUCCESS } from '../types';

import { db } from '../../Utils/fb.config';

// create store
export const createNewStore =
  ({ store, closeModal }) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({ type: LOADING_UI });

    const auth = getState().firebase.auth;

    db.ref(`/stores/${auth.uid}`)
      .set({
        ...store,
        branches: {},
      })
      .then((value) => {
        closeModal();
        dispatch({
          type: SET_SUCCESS,
          payload: `store created`,
        });
        dispatch({ type: CLEAR_ERRORS });
        // history.push("/dashboard");
      })
      .catch((error) => {
        console.log(`Store Create Error`);
        dispatch({
          type: SET_ERRORS,
          payload: error,
        });
      });
  };

// updateStore store
export const updateStore =
  ({ store_id, store_data }) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({ type: LOADING_UI });

    // const firebase = getFirebase();

    db.ref(`/stores/${store_id}`)
      .update({
        ...store_data,
      })
      .then((value) => {
        dispatch({
          type: SET_SUCCESS,
          payload: `store updated successfully`,
        });
        dispatch({ type: CLEAR_ERRORS });
        // history.push("/dashboard");
      })
      .catch((error) => {
        console.log(`Store Update Error`);
        dispatch({
          type: SET_ERRORS,
          payload: error,
        });
      });
  };

import { isLoaded, useFirebaseConnect } from 'react-redux-firebase';

import AddToBasket from './AddToBasket';
import AssignDialog from './AssignRiderModal';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Loader from '../../../../../../Base/Loader';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
});

export default function MediaCard(props) {
  const {
    order: {
      value: {
        order_status = 'pending',
        // receiver_address,
        receiver_lat,
        receiver_long,
        clientID,
        time,
        client_name,
        client_phone,
        store_id,
        branch_id,
        delivery_lat,
        delivery_long,
        gas_brand,
        gas_price,
        gas_size,
        date,
        cartID,
        rider_id = undefined,
        ...rest
      },
    },
  } = props;

  console.log(JSON.stringify(props.order, null, 2), 'order gas');
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  useFirebaseConnect(`merchants/${store_id}`); // sync /merchants/store_id from firebase into redux
  const merchant = useSelector(
    ({
      firebase: {
        data: { merchants },
      },
    }) => merchants && merchants[store_id],
  );

  if (!isLoaded(merchant)) {
    return <Loader />;
  }

  console.log(merchant, 'meeeeeeeeeeeeeeeeeeeeeeeeeerchant');

  const {
    merchant_name,

    sender_lat = -1.2627006,
    sender_long = 36.8063753,
    phone_number: sender_phone,
    user_Id: merchant_id,
  } = merchant;

  const payload = {
    sender_name: merchant_name,
    sender_lat,
    sender_long,
    sender_phone,
    merchant_id,
    sender_id: merchant_id,
  };

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Order Status: {order_status}{' '}
          </Typography>
          <Typography gutterBottom variant="h5" component="h2">
            {/* {receiver_address} */}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Client: {client_name} {client_phone}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Brand: {gas_brand}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Details :{gas_size} @ksh{gas_price}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <AssignDialog
          order={{
            order_status,
            // receiver_address,
            receiver_lat,
            receiver_long,
            clientID,
            time,
            store_id,
            branch_id,
            client_name,
            client_phone,
            ...payload,
            // dummy

            date,
            cartID,
            //duplicate,
            order_date: date,
            order_time: time,
            rider_id,
            //dummy,
            delivery_fee: '150',
            fee_paid_by: 'Merchant',
            ...rest,
          }}
        />
        {/* <Typography variant="h5" component="h2">
                    {bull}or{bull}
                </Typography> */}
        {/* <AddToBasket
                    order={{
                        order_status,
                        // receiver_address,
                        receiver_lat,
                        receiver_long,
                        clientID,
                        time,
                        client_name,
                        client_phone,
                        sender_name,
                        sender_phone,
                        date,
                        cartID,
                        sender_id,
                        sender_lat,
                        sender_long,
                        rider_id,
                    }}
                /> */}
      </CardActions>
    </Card>
  );
}

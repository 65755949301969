import React, { useState } from 'react';

import Avatar from '@material-ui/core/Avatar';
import BranchCardActions from './BranchCardActions';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import StoreIcon from '@material-ui/icons/Store';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
});

function ImgMediaCard(props) {
  const classes = useStyles();
  const {
    branch: { branch_name, store_id, branch_id },
    url,
    deleteJob,
    userRole,
  } = props;

  return (
    <>
      <Card className={classes.root}>
        <CardActionArea>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" className={classes.avatar}>
                #{branch_id}
              </Avatar>
            }
            action={
              <IconButton aria-label="settings">
                <BranchCardActions
                  branch={{
                    ...props.branch,
                  }}
                />
              </IconButton>
            }
            title={branch_name}
            // subheader={formatDate(start_date)}
          />

          <CardContent>
            <Typography gutterBottom variant="h6" component="h2"></Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {branch_id}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button
            size="small"
            color="primary"
            variant="contained"
            component={Link}
            to={`${url}/${branch_id}/branch-details`}
            startIcon={<StoreIcon />}
          >
            View Branch
          </Button>

          {/* <Button
                        size="small"
                        disabled={userRole === "user" ? true : false}
                        color="secondary"
                        variant="outlined"
                        startIcon={<DeleteIcon />}
                        onClick={() => {
                            setConfirmDialog({
                                isOpen: true,
                                title: `Are you sure to delete ${branch_name}?`,
                                subTitle:
                                    "You can't undo this operation as every associated data will be lost",
                                onConfirm: () => {
                                    onDelete(branch_name);
                                },
                            });
                        }}
                    >
                        Delete
                    </Button> */}
        </CardActions>
      </Card>
    </>
  );
}

export default ImgMediaCard;
